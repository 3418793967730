import React from 'react';

interface MarkdownRendererProps {
  content: string;
  className?: string;
}

/**
 * Componente para renderizar conteúdo Markdown
 * Esta é uma implementação que converte elementos do Markdown para HTML com classes CSS apropriadas
 */
const MarkdownRenderer: React.FC<MarkdownRendererProps> = ({ content, className = '' }) => {
  // Função para converter Markdown para HTML
  const convertMarkdownToHtml = (markdown: string): string => {
    let html = markdown;
    
    // Converter cabeçalhos com classes apropriadas
    html = html.replace(/^# (.*$)/gm, '<h1 class="blog-content-h1">$1</h1>');
    html = html.replace(/^## (.*$)/gm, '<h2 class="blog-content-h2">$1</h2>');
    html = html.replace(/^### (.*$)/gm, '<h3 class="blog-content-h3">$1</h3>');
    html = html.replace(/^#### (.*$)/gm, '<h4 class="blog-content-h4">$1</h4>');
    
    // Converter parágrafos com classes
    html = html.replace(/^\s*(\n)?(.+)/gm, function(m) {
      return /\<(\/)?(h\d|ul|ol|li|blockquote|pre|img)/.test(m) ? m : '<p class="blog-content-p">' + m + '</p>';
    });
    
    // Converter negrito
    html = html.replace(/\*\*(.*?)\*\*/g, '<strong class="blog-content-strong">$1</strong>');
    
    // Converter itálico
    html = html.replace(/\*(.*?)\*/g, '<em class="blog-content-em">$1</em>');
    
    // Converter listas não ordenadas
    html = html.replace(/^\s*-\s*(.*)/gm, '<li class="blog-content-li">$1</li>');
    html = html.replace(/(<li class="blog-content-li">.*<\/li>\n)+/g, '<ul class="blog-content-ul">$&</ul>');
    
    // Converter listas ordenadas
    html = html.replace(/^\s*\d+\.\s*(.*)/gm, '<li class="blog-content-li">$1</li>');
    html = html.replace(/(<li class="blog-content-li">.*<\/li>\n)+/g, '<ol class="blog-content-ol">$&</ol>');
    
    // Converter links com classes
    html = html.replace(/\[([^\]]+)\]\(([^)]+)\)/g, '<a href="$2" class="blog-content-link">$1</a>');
    
    // Converter imagens com classes
    html = html.replace(/!\[([^\]]+)\]\(([^)]+)\)/g, '<img src="$2" alt="$1" class="blog-content-img">');
    
    // Converter blocos de código com classes
    html = html.replace(/```([\s\S]*?)```/g, '<pre class="blog-content-pre"><code class="blog-content-code">$1</code></pre>');
    
    // Converter código inline com classes
    html = html.replace(/`([^`]+)`/g, '<code class="blog-content-code">$1</code>');
    
    // Converter citações com classes
    html = html.replace(/^> (.*$)/gm, '<blockquote class="blog-content-blockquote">$1</blockquote>');
    
    // Limpar espaços extras
    html = html.replace(/\n\n+/g, '</p><p class="blog-content-p">');
    
    return html;
  };
  
  return (
    <div 
      className={`blog-content ${className}`}
      dangerouslySetInnerHTML={{ __html: convertMarkdownToHtml(content) }}
    />
  );
};

export default MarkdownRenderer; 