import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { submitContactForm } from '../utils/supabaseClient';
import { ContactFormData } from '../types/contact';
import SEO from '../components/common/SEO';

const Contact: React.FC = () => {
  const location = useLocation();
  
  const [formData, setFormData] = useState<ContactFormData>({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: ''
  });
  
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState('');

  // Lê os parâmetros de consulta e pré-preenche o formulário
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const service = params.get('service');
    const plan = params.get('plan');
    const action = params.get('action');
    
    // Verifica se há serviços incluídos (para o caso do Pacote Completo)
    const includedServicesCount = parseInt(params.get('included_services_count') || '0', 10);
    const includedServices = [];
    
    for (let i = 1; i <= includedServicesCount; i++) {
      const includedService = params.get(`included_service_${i}`);
      if (includedService) {
        includedServices.push(includedService);
      }
    }
    
    if (service || plan) {
      let subject = '';
      let message = '';
      
      // Define o assunto com base nos parâmetros
      if (action === 'contract') {
        subject = 'Sales';
      } else if (action === 'quote') {
        subject = 'Sales';
      } else {
        subject = 'General Inquiry';
      }
      
      // Constrói a mensagem com base nos parâmetros
      if (service && plan) {
        message = `Olá,\n\n`;
        
        if (service === 'Pacote Completo') {
          message += `Gostaria de ${action === 'contract' ? 'contratar' : 'solicitar um orçamento para'} o ${plan}.\n\n`;
          
          if (includedServices.length > 0) {
            message += `Serviços incluídos:\n`;
            includedServices.forEach(includedService => {
              message += `- ${includedService}\n`;
            });
            message += `\n`;
          }
        } else {
          message += `Gostaria de ${action === 'contract' ? 'contratar' : 'solicitar um orçamento para'} o seguinte serviço:\n\n`;
          message += `Serviço: ${service}\n`;
          message += `Plano: ${plan}\n\n`;
        }
        
        message += `Por favor, entre em contato comigo para discutirmos os detalhes e próximos passos.\n\n`;
        message += `Atenciosamente,`;
      }
      
      // Atualiza o estado do formulário
      setFormData(prev => ({
        ...prev,
        subject,
        message
      }));
    }
  }, [location.search]);

  // Structured data for contact page
  const contactSchema = {
    "@context": "https://schema.org",
    "@type": "ContactPage",
    "name": "Contato Nyctea",
    "description": "Entre em contato com a equipe Nyctea para saber como podemos ajudar seu negócio.",
    "mainEntity": {
      "@type": "Organization",
      "name": "Nyctea",
      "email": "contato@nyctea.com.br",
      "telephone": "+55 11 98765-4321",
      "address": {
        "@type": "PostalAddress",
        "addressLocality": "São Paulo",
        "addressRegion": "SP",
        "addressCountry": "BR"
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    
    // Validação específica para o campo de telefone
    if (name === 'phone') {
      // Remove caracteres não numéricos
      const numericValue = value.replace(/\D/g, '');
      
      // Formata o número com DDD e número
      let formattedValue = '';
      if (numericValue.length <= 2) {
        formattedValue = numericValue;
      } else if (numericValue.length <= 7) {
        formattedValue = `(${numericValue.slice(0, 2)}) ${numericValue.slice(2)}`;
      } else {
        formattedValue = `(${numericValue.slice(0, 2)}) ${numericValue.slice(2, 7)}-${numericValue.slice(7, 11)}`;
      }
      
      setFormData(prev => ({
        ...prev,
        [name]: formattedValue
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitError('');
    
    try {
      // Enviar dados para o Supabase
      const response = await submitContactForm(formData);
      
      if (response.success) {
        setSubmitSuccess(true);
        setFormData({
          name: '',
          email: '',
          phone: '',
          subject: '',
          message: ''
        });
      } else {
        throw new Error('Falha ao enviar o formulário');
      }
    } catch (error) {
      setSubmitError('Ocorreu um erro ao enviar sua mensagem. Por favor, tente novamente.');
      console.error('Form submission error:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <SEO 
        title="Contato"
        description="Entre em contato com a Nyctea para saber como podemos ajudar seu negócio com soluções de IA e automação personalizadas."
        canonical="/contact"
        structuredData={contactSchema}
      />
      
      {/* Page Header */}
      <section className="bg-primary-dark py-16">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto text-center">
            <h1 className="text-4xl font-bold text-background-light mb-4">Entre em Contato</h1>
            <p className="text-xl text-background">
              Estamos prontos para ajudar você a transformar seu negócio
            </p>
          </div>
        </div>
      </section>

      {/* Contact Form and Info */}
      <section className="py-16">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
            {/* Contact Form */}
            <div className="bg-background-light rounded-lg shadow-xl p-8">
              <h2 className="text-2xl font-bold text-primary-dark mb-6">Envie-nos uma mensagem</h2>
              
              {submitSuccess ? (
                <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-6">
                  <p>Obrigado pela sua mensagem! Entraremos em contato em breve.</p>
                </div>
              ) : (
                <form onSubmit={handleSubmit}>
                  {submitError && (
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-6">
                      <p>{submitError}</p>
                    </div>
                  )}
                  
                  <div className="mb-4">
                    <label htmlFor="name" className="block text-primary-dark mb-2">Nome</label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                      className="w-full px-4 py-2 rounded bg-background border border-primary text-text focus:outline-none focus:ring-2 focus:ring-accent"
                    />
                  </div>
                  
                  <div className="mb-4">
                    <label htmlFor="email" className="block text-primary-dark mb-2">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                      className="w-full px-4 py-2 rounded bg-background border border-primary text-text focus:outline-none focus:ring-2 focus:ring-accent"
                    />
                  </div>
                  
                  <div className="mb-4">
                    <label htmlFor="phone" className="block text-primary-dark mb-2">Celular</label>
                    <input
                      type="tel"
                      id="phone"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      required
                      placeholder="(00) 00000-0000"
                      className="w-full px-4 py-2 rounded bg-background border border-primary text-text focus:outline-none focus:ring-2 focus:ring-accent"
                    />
                  </div>
                  
                  <div className="mb-4">
                    <label htmlFor="subject" className="block text-primary-dark mb-2">Assunto</label>
                    <select
                      id="subject"
                      name="subject"
                      value={formData.subject}
                      onChange={handleChange}
                      required
                      className="w-full px-4 py-2 rounded bg-background border border-primary text-text focus:outline-none focus:ring-2 focus:ring-accent"
                    >
                      <option value="">Selecione um assunto</option>
                      <option value="General Inquiry">Informações Gerais</option>
                      <option value="Support">Suporte Técnico</option>
                      <option value="Sales">Vendas</option>
                      <option value="Partnership">Parceria</option>
                    </select>
                  </div>
                  
                  <div className="mb-6">
                    <label htmlFor="message" className="block text-primary-dark mb-2">Mensagem</label>
                    <textarea
                      id="message"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      required
                      rows={10}
                      className="w-full px-4 py-2 rounded bg-background border border-primary text-text focus:outline-none focus:ring-2 focus:ring-accent"
                    ></textarea>
                  </div>
                  
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="btn-accent w-full py-3 text-lg font-semibold flex justify-center items-center"
                  >
                    {isSubmitting ? 'Enviando...' : 'Enviar Mensagem'}
                  </button>
                </form>
              )}
            </div>
            
            {/* Contact Information */}
            <div className="bg-primary rounded-lg shadow-xl p-8">
              <h2 className="text-2xl font-bold text-background-light mb-6">Informações de Contato</h2>
              
              <div className="space-y-8">
                <div>
                  <h3 className="text-xl font-semibold text-background-light mb-2">Detalhes de Contato</h3>
                  <p className="text-background mb-1">
                    <span className="font-semibold">Email:</span>{' '}
                    <a href="mailto:nyctea.publicidade@gmail.com" className="text-accent-light hover:text-background-light">
                      nyctea.publicidade@gmail.com
                    </a>
                  </p>
                  <p className="text-background">
                    <span className="font-semibold">Celular:</span>{' '}
                    <a href="tel:+5512997412417" className="text-accent-light hover:text-background-light">
                      (12) 99741-2417
                    </a>
                  </p>
                </div>
                
                <div>
                  <h3 className="text-xl font-semibold text-background-light mb-2">Redes Sociais</h3>
                  <div className="flex space-x-4">
                    <a href="https://www.instagram.com/getenergy.nyctea" target="_blank" rel="noopener noreferrer" className="text-accent-light hover:text-background-light">
                      <svg className="h-5 w-5 fill-current" viewBox="0 0 24 24">
                        <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                      </svg>
                    </a>
                    <a href="https://web.facebook.com/people/GetEnergy-Nyctea/61559048536913/" target="_blank" rel="noopener noreferrer" className="text-accent-light hover:text-background-light">
                      <svg className="h-5 w-5 fill-current" viewBox="0 0 24 24">
                        <path d="M22.675 0h-21.35c-.732 0-1.325.593-1.325 1.325v21.351c0 .731.593 1.324 1.325 1.324h11.495v-9.294h-3.128v-3.622h3.128v-2.671c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24l-1.918.001c-1.504 0-1.795.715-1.795 1.763v2.313h3.587l-.467 3.622h-3.12v9.293h6.116c.73 0 1.323-.593 1.323-1.325v-21.35c0-.732-.593-1.325-1.325-1.325z" />
                      </svg>
                    </a>
                    <a href="https://www.linkedin.com/in/aronifanger/" target="_blank" rel="noopener noreferrer" className="text-accent-light hover:text-background-light">
                      <svg className="h-5 w-5 fill-current" viewBox="0 0 24 24">
                        <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
                      </svg>
                    </a>
                    <a href="https://api.whatsapp.com/send?phone=5512997412417" target="_blank" rel="noopener noreferrer" className="text-accent-light hover:text-background-light">
                      <svg className="h-5 w-5 fill-current" viewBox="0 0 24 24">
                        <path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z" />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact; 