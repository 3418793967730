import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { getServiceBySlug } from '../../data/servicesData';
import SocialMediaPricingTable from '../../components/services/SocialMediaPricingTable';
import ContentStrategyInfo from '../../components/services/ContentStrategyInfo';
import ContentExampleCard from '../../components/services/ContentExampleCard';
import FAQSection from '../../components/services/FAQSection';
import { trackServiceInteraction } from '../../utils/analytics';
import ServiceNavigation from '../../components/services/ServiceNavigation';
import SEO from '../../components/common/SEO';

// Imagem para a página de redes sociais
import socialMediaImage from '../../assets/images/home_services_social_media.png';
import logoInstagram from '../../assets/images/logo_instagram.jpeg';
import logoFacebook from '../../assets/images/logo_meta.png';
import logoTiktok from '../../assets/images/logo_tiktok.png'; // Você precisará adicionar esta imagem

// Definindo o tipo para as estratégias de conteúdo
interface ContentStrategy {
  title: string;
  description: string;
  features: string[];
  icon: 'content' | 'engagement' | 'analytics';
}

const SocialMedia: React.FC = () => {
  const service = getServiceBySlug('social-media');
  const pricingSectionRef = useRef<HTMLElement>(null);
  const faqSectionRef = useRef<HTMLElement>(null);

  if (!service) {
    return <div>Serviço não encontrado</div>;
  }

  // Função para rolar suavemente até a seção de preços
  const scrollToPricing = () => {
    try {
      // Rastreia o clique no botão "Ver Planos"
      trackServiceInteraction(service.slug, service.title, 'view_pricing');
      
      // Rola suavemente até a seção de preços
      pricingSectionRef.current?.scrollIntoView({ 
        behavior: 'smooth',
        block: 'start'
      });
    } catch (error) {
      console.error('Erro ao rolar para a seção de preços:', error);
    }
  };

  // Função para rolar suavemente até a seção de FAQ
  const scrollToFAQ = () => {
    try {
      // Rastreia o clique no botão "Dúvidas Frequentes"
      trackServiceInteraction(service.slug, service.title, 'view_faq');
      
      // Rola suavemente até a seção de FAQ
      faqSectionRef.current?.scrollIntoView({ 
        behavior: 'smooth',
        block: 'start'
      });
    } catch (error) {
      console.error('Erro ao rolar para a seção de FAQ:', error);
    }
  };

  // Dados para a seção de estratégias de conteúdo
  const contentStrategies: ContentStrategy[] = [
    {
      title: "Criação de Conteúdo Envolvente",
      description: "Desenvolvemos conteúdo que ressoa com seu público-alvo e gera engajamento real.",
      features: [
        "Posts visualmente atrativos e profissionais",
        "Copywriting persuasivo e alinhado com sua marca",
        "Calendário editorial estratégico",
        "Conteúdo adaptado para cada plataforma",
        "Uso de tendências e hashtags relevantes"
      ],
      icon: "content"
    },
    {
      title: "Gestão de Comunidade",
      description: "Construímos e gerenciamos uma comunidade engajada ao redor da sua marca.",
      features: [
        "Resposta rápida a comentários e mensagens",
        "Moderação de conteúdo e interações",
        "Criação de relacionamento com seguidores",
        "Identificação e engajamento com influenciadores",
        "Gestão de crises e feedback negativo"
      ],
      icon: "engagement"
    },
    {
      title: "Análise e Otimização",
      description: "Monitoramos o desempenho e otimizamos continuamente sua estratégia digital.",
      features: [
        "Relatórios detalhados de performance",
        "Análise de métricas-chave (alcance, engajamento, conversões)",
        "Testes A/B para otimizar resultados",
        "Ajustes estratégicos baseados em dados",
        "Recomendações para crescimento contínuo"
      ],
      icon: "analytics"
    }
  ];

  // Dados para a seção de exemplos de conteúdo
  const contentExamples = [
    {
      title: "Carrossel Educativo",
      imageSrc: logoInstagram,
      platform: "Instagram",
      description: "Série de posts em carrossel explicando conceitos importantes do seu nicho, posicionando sua marca como autoridade."
    },
    {
      title: "Vídeo Demonstrativo",
      imageSrc: logoTiktok,
      platform: "TikTok",
      description: "Vídeos curtos e dinâmicos mostrando seu produto ou serviço em ação, aproveitando tendências da plataforma."
    },
    {
      title: "Campanha Promocional",
      imageSrc: logoFacebook,
      platform: "Facebook",
      description: "Anúncios segmentados para seu público-alvo, com ofertas especiais e chamadas para ação claras."
    }
  ];

  // Dados para a seção de FAQ
  const faqs = [
    {
      question: "Quanto tempo leva para ver resultados nas redes sociais?",
      answer: "O crescimento orgânico nas redes sociais é um processo gradual. Normalmente, você começará a ver melhorias no engajamento em 1-2 meses, enquanto resultados mais significativos em termos de seguidores e conversões geralmente aparecem após 3-6 meses de trabalho consistente. Campanhas pagas podem acelerar esse processo, trazendo resultados mais rápidos."
    },
    {
      question: "Quais redes sociais são mais importantes para o meu negócio?",
      answer: "Isso depende do seu público-alvo e tipo de negócio. Durante nossa análise inicial, identificamos onde seu público está mais ativo e quais plataformas oferecem melhor retorno para seu setor. Por exemplo, empresas B2B geralmente se beneficiam mais do LinkedIn, enquanto negócios visuais como restaurantes e moda têm ótimos resultados no Instagram. Focamos nas plataformas que trarão os melhores resultados para seu caso específico."
    },
    {
      question: "Vocês criam todo o conteúdo ou precisamos fornecer materiais?",
      answer: "Nossa equipe cria todo o conteúdo necessário, incluindo textos, imagens e vídeos. No entanto, para resultados ideais, recomendamos uma colaboração. Você conhece seu negócio melhor que ninguém, então informações sobre produtos, serviços, promoções e eventos são valiosas. Também podemos utilizar materiais que você já possui, como fotos de produtos ou depoimentos de clientes, para enriquecer o conteúdo."
    },
    {
      question: "Como vocês medem o sucesso das estratégias de redes sociais?",
      answer: "Utilizamos métricas específicas alinhadas aos seus objetivos de negócio. Isso pode incluir crescimento de seguidores, taxa de engajamento, alcance, cliques para o site, geração de leads ou conversões diretas. Fornecemos relatórios mensais detalhados com estas métricas e análises comparativas, mostrando claramente o progresso e o retorno sobre o investimento."
    },
    {
      question: "É possível mudar a estratégia ou o tipo de conteúdo ao longo do tempo?",
      answer: "Absolutamente! Na verdade, isso é recomendado. As redes sociais estão em constante evolução, assim como seu negócio. Revisamos regularmente a performance da estratégia e fazemos ajustes baseados nos resultados e feedback. Se algo não está funcionando como esperado ou se surgem novas oportunidades, adaptamos a abordagem para maximizar os resultados."
    },
    {
      question: "Vocês também gerenciam anúncios pagos nas redes sociais?",
      answer: "Sim, oferecemos serviços completos de gestão de anúncios pagos no Facebook, Instagram, TikTok e outras plataformas. Isso inclui a criação de campanhas, segmentação de público, design de criativos, otimização contínua e relatórios de performance. Os anúncios pagos são uma excelente forma de acelerar resultados e complementar sua estratégia orgânica."
    }
  ];

  // Structured data para o serviço
  const serviceSchema = {
    "@context": "https://schema.org",
    "@type": "Service",
    "name": service.title,
    "description": service.description,
    "provider": {
      "@type": "Organization",
      "name": "Nyctea Platform"
    }
  };

  return (
    <>
      <SEO 
        title={`${service.title} | Nyctea Platform`}
        description={service.description}
        canonical={`/services/${service.slug}`}
        structuredData={serviceSchema}
      />
      
      {/* Navegação lateral entre serviços */}
      <ServiceNavigation currentServiceSlug={service.slug} />
      
      <div>
        {/* Hero Section */}
        <section className="bg-primary-dark py-16">
          <div className="container mx-auto px-4">
            <div className="flex flex-col md:flex-row items-center">
              <div className="md:w-1/2 mb-8 md:mb-0">
                <div className="text-left">
                  <Link to="/services" className="text-background hover:text-accent mb-4 inline-flex items-center">
                    <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 19l-7-7m0 0l7-7m-7 7h18"></path>
                    </svg>
                    Voltar para Serviços
                  </Link>
                  <h1 className="text-4xl font-bold text-background-light mb-4">{service.title}</h1>
                  <p className="text-xl text-background mb-6">Aumente sua presença digital com conteúdo estratégico e envolvente. Conquiste mais seguidores, engajamento e conversões com uma gestão profissional das suas redes sociais.</p>
                  <div className="flex flex-wrap gap-4">
                    <button 
                      onClick={scrollToPricing}
                      className="btn-accent text-lg px-8 py-3 inline-block"
                    >
                      Ver Planos
                    </button>
                    <button 
                      onClick={scrollToFAQ}
                      className="btn-outline-accent text-lg px-8 py-3 inline-block"
                    >
                      Dúvidas Frequentes
                    </button>
                  </div>
                </div>
              </div>
              <div className="md:w-1/2">
                <div className="bg-background-light p-6 rounded-lg">
                  <img 
                    src={socialMediaImage} 
                    alt={service.title} 
                    className="w-full h-auto object-contain rounded-lg"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Description Section */}
        <section className="py-16 bg-background">
          <div className="container mx-auto px-4">
            <div className="max-w-3xl mx-auto">
              <h2 className="text-3xl font-bold text-primary-dark mb-6">Sobre {service.title}</h2>
              <div className="prose prose-lg text-primary mb-8">
                <p>Nossa Gestão de Redes Sociais é uma solução completa para empresas que desejam maximizar sua presença digital. Desenvolvemos estratégias personalizadas para cada plataforma, criando conteúdo relevante e envolvente que ressoa com seu público-alvo.</p>
                <p>Nossa equipe de especialistas cuida de todo o processo: desde a criação de calendários editoriais, design de posts, copywriting, até o monitoramento de métricas e análise de resultados. Respondemos a comentários, gerenciamos a comunidade e identificamos oportunidades de crescimento.</p>
              </div>
              
              <div className="bg-primary-dark/5 p-6 rounded-lg mb-8">
                <h3 className="text-xl font-semibold text-primary-dark mb-4">Por que investir em Redes Sociais?</h3>
                <ul className="space-y-2">
                  <li className="flex items-start">
                    <svg className="h-5 w-5 text-accent mt-0.5 mr-2 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                    <span>Aumente a visibilidade da sua marca onde seu público já está</span>
                  </li>
                  <li className="flex items-start">
                    <svg className="h-5 w-5 text-accent mt-0.5 mr-2 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                    <span>Construa relacionamentos duradouros com clientes e prospects</span>
                  </li>
                  <li className="flex items-start">
                    <svg className="h-5 w-5 text-accent mt-0.5 mr-2 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                    <span>Direcione tráfego qualificado para seu site ou loja</span>
                  </li>
                  <li className="flex items-start">
                    <svg className="h-5 w-5 text-accent mt-0.5 mr-2 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                    <span>Estabeleça autoridade e credibilidade no seu setor</span>
                  </li>
                  <li className="flex items-start">
                    <svg className="h-5 w-5 text-accent mt-0.5 mr-2 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                    <span>Obtenha insights valiosos sobre seu público e mercado</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        {/* Content Strategies Section */}
        <section className="py-16 bg-background-light">
          <div className="container mx-auto px-4">
            <div className="max-w-5xl mx-auto">
              <div className="text-center mb-12">
                <h2 className="text-3xl font-bold text-primary-dark mb-4">Nossa Abordagem</h2>
                <p className="text-xl text-primary max-w-2xl mx-auto">
                  Conheça as estratégias que utilizamos para impulsionar sua presença nas redes sociais
                </p>
              </div>
              
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                {contentStrategies.map((strategy, index) => (
                  <ContentStrategyInfo 
                    key={index}
                    title={strategy.title}
                    description={strategy.description}
                    features={strategy.features}
                    icon={strategy.icon}
                  />
                ))}
              </div>
            </div>
          </div>
        </section>

        {/* Content Examples Section */}
        <section className="py-16 bg-background">
          <div className="container mx-auto px-4">
            <div className="max-w-5xl mx-auto">
              <div className="text-center mb-12">
                <h2 className="text-3xl font-bold text-primary-dark mb-4">Tipos de Conteúdo</h2>
                <p className="text-xl text-primary max-w-2xl mx-auto">
                  Exemplos de conteúdos que criamos para diferentes plataformas
                </p>
              </div>
              
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                {contentExamples.map((example, index) => (
                  <ContentExampleCard 
                    key={index}
                    title={example.title}
                    imageSrc={example.imageSrc}
                    platform={example.platform}
                    description={example.description}
                  />
                ))}
              </div>
            </div>
          </div>
        </section>

        {/* Pricing Section */}
        <section ref={pricingSectionRef} id="pricing" className="py-16 bg-background-light">
          <div className="container mx-auto px-4">
            <div className="max-w-5xl mx-auto">
              <div className="text-center mb-12">
                <h2 className="text-3xl font-bold text-primary-dark mb-4">Nossos Planos</h2>
                <p className="text-xl text-primary max-w-2xl mx-auto">
                  Escolha o plano que melhor se adapta às necessidades do seu negócio
                </p>
              </div>
              
              <SocialMediaPricingTable />
              
              <div className="mt-12 text-center">
                <p className="text-primary mb-6">
                  Entre em contato para discutir opções personalizadas ou para agendar uma consultoria.
                </p>
                <Link to="/contact" className="btn-accent text-lg px-8 py-3">
                  Fale Conosco
                </Link>
              </div>
            </div>
          </div>
        </section>

        {/* FAQ Section */}
        <section ref={faqSectionRef} id="faq" className="py-16 bg-background">
          <div className="container mx-auto px-4">
            <div className="max-w-3xl mx-auto">
              <div className="text-center mb-12">
                <h2 className="text-3xl font-bold text-primary-dark mb-4">Perguntas Frequentes</h2>
                <p className="text-xl text-primary max-w-2xl mx-auto">
                  Respostas para as dúvidas mais comuns sobre nossa gestão de redes sociais
                </p>
              </div>
              
              <FAQSection faqs={faqs} />
            </div>
          </div>
        </section>

        {/* CTA Section */}
        <section className="bg-primary-dark py-16">
          <div className="container mx-auto px-4 text-center">
            <h2 className="text-3xl font-bold text-background-light mb-6">
              Pronto para transformar suas redes sociais?
            </h2>
            <p className="text-xl text-background mb-8 max-w-2xl mx-auto">
              Agende uma consultoria gratuita e descubra como nossa Gestão de Redes Sociais pode ajudar seu negócio a alcançar novos patamares de visibilidade e engajamento.
            </p>
            <div className="flex flex-wrap justify-center gap-4">
              <Link to="/contact?service=Gestão%20de%20Redes%20Sociais&action=consultation" className="btn-accent text-lg px-8 py-3">
                Agendar Consultoria
              </Link>
              <Link to="/contact" className="btn-outline-accent text-lg px-8 py-3">
                Falar com Especialista
              </Link>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default SocialMedia; 