import React from 'react';
import { Link } from 'react-router-dom';
import { Service } from '../../types/service';
import { services } from '../../data/servicesData';
import { trackServiceInteraction } from '../../utils/analytics';

interface ServiceNavigationProps {
  currentServiceSlug: string;
}

const ServiceNavigation: React.FC<ServiceNavigationProps> = ({ currentServiceSlug }) => {
  // Encontra o índice do serviço atual
  const currentIndex = services.findIndex(service => service.slug === currentServiceSlug);
  
  // Se o serviço não for encontrado, não renderiza nada
  if (currentIndex === -1) return null;
  
  // Implementação circular: se estiver no primeiro, o anterior é o último; se estiver no último, o próximo é o primeiro
  const prevService = currentIndex > 0 ? services[currentIndex - 1] : services[services.length - 1];
  const nextService = currentIndex < services.length - 1 ? services[currentIndex + 1] : services[0];
  
  // Função para rastrear a navegação entre serviços
  const trackNavigation = (direction: 'prev' | 'next', targetSlug: string, targetTitle: string) => {
    try {
      trackServiceInteraction(
        currentServiceSlug,
        services[currentIndex].title,
        `navigate_${direction}_to_${targetSlug}`
      );
    } catch (error) {
      console.error(`Erro ao rastrear navegação ${direction}:`, error);
    }
  };
  
  return (
    <div className="fixed bottom-8 md:bottom-12 w-full pointer-events-none z-10">
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center">
          {/* Botão para serviço anterior - mais discreto e recolhido */}
          <div className="relative">
            <Link 
              to={`/services/${prevService.slug}`}
              className="group bg-white/30 hover:bg-white/90 text-primary-dark/50 hover:text-primary-dark p-3 rounded-full shadow-md hover:shadow-lg transition-all duration-300 transform translate-x-1 hover:translate-x-0 hover:scale-110 pointer-events-auto flex items-center"
              onClick={() => trackNavigation('prev', prevService.slug, prevService.title)}
              title={`Ir para ${prevService.title}`}
              aria-label={`Ir para serviço anterior: ${prevService.title}`}
            >
              <svg className="w-5 h-5 md:w-6 md:h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"></path>
              </svg>
              <span className="max-w-0 overflow-hidden group-hover:max-w-xs transition-all duration-500 ease-in-out ml-0 md:ml-2 whitespace-nowrap">
                {prevService.title}
              </span>
            </Link>
          </div>
          
          {/* Indicador de posição atual */}
          <div className="hidden md:flex items-center space-x-1">
            {services.map((service, index) => (
              <div 
                key={service.slug}
                className={`w-2 h-2 rounded-full ${index === currentIndex ? 'bg-primary' : 'bg-gray-300'}`}
                title={service.title}
              />
            ))}
          </div>
          
          {/* Botão para próximo serviço - mais discreto e recolhido */}
          <div className="relative">
            <Link 
              to={`/services/${nextService.slug}`}
              className="group bg-white/30 hover:bg-white/90 text-primary-dark/50 hover:text-primary-dark p-3 rounded-full shadow-md hover:shadow-lg transition-all duration-300 transform -translate-x-1 hover:translate-x-0 hover:scale-110 pointer-events-auto flex items-center"
              onClick={() => trackNavigation('next', nextService.slug, nextService.title)}
              title={`Ir para ${nextService.title}`}
              aria-label={`Ir para próximo serviço: ${nextService.title}`}
            >
              <span className="max-w-0 overflow-hidden group-hover:max-w-xs transition-all duration-500 ease-in-out mr-0 md:mr-2 whitespace-nowrap">
                {nextService.title}
              </span>
              <svg className="w-5 h-5 md:w-6 md:h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path>
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceNavigation; 