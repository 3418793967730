import { Service, ServiceOption, ComboOption } from '../types/service';
import automationImage from '../assets/images/home_services_automation.png';
import socialMediaImage from '../assets/images/home_services_social_media.png';
import presenceImage from '../assets/images/home_services_presence.png';
import indoorMediaImage from '../assets/images/home_services_indoor.png';

// Services data
export const services: Service[] = [
  {
    id: 1,
    title: 'Automação Inteligente',
    description: 'Utilize chatbots e automação para otimizar o atendimento ao cliente, reduzir tempo de resposta e aumentar a satisfação.',
    longDescription: 'Nossas soluções de Automação Inteligente transformam a maneira como sua empresa interage com os clientes. Utilizando tecnologias avançadas de IA e machine learning, desenvolvemos chatbots personalizados que entendem as necessidades dos seus clientes e fornecem respostas precisas em tempo real. Automatizamos processos repetitivos, liberando sua equipe para focar em tarefas estratégicas e de maior valor. Com nossa solução, você reduz custos operacionais, melhora a eficiência e proporciona uma experiência consistente e de alta qualidade para seus clientes 24 horas por dia, 7 dias por semana.',
    image: automationImage,
    slug: 'automation'
  },
  {
    id: 2,
    title: 'Gestão de Redes Sociais',
    description: 'Criação e gerenciamento estratégico de conteúdo para aumentar o engajamento, visibilidade e conversão da sua marca.',
    longDescription: 'Nossa Gestão de Redes Sociais é uma solução completa para empresas que desejam maximizar sua presença digital. Desenvolvemos estratégias personalizadas para cada plataforma, criando conteúdo relevante e envolvente que ressoa com seu público-alvo. Nossa equipe de especialistas cuida de todo o processo: desde a criação de calendários editoriais, design de posts, copywriting, até o monitoramento de métricas e análise de resultados. Respondemos a comentários, gerenciamos a comunidade e identificamos oportunidades de crescimento. Com relatórios detalhados, você acompanha o desempenho e o retorno sobre o investimento em tempo real.',
    image: socialMediaImage,
    slug: 'social-media'
  },
  /* Serviço de Presença Digital Otimizada temporariamente removido
  {
    id: 3,
    title: 'Presença Digital Otimizada',
    description: 'Melhore sua visibilidade online com estratégias avançadas de SEO, Google Meu Negócio e presença local.',
    longDescription: 'Nossa solução de Presença Digital Otimizada coloca seu negócio à frente da concorrência nos resultados de busca. Combinamos técnicas avançadas de SEO (Search Engine Optimization) com estratégias de marketing local para garantir que sua empresa seja encontrada pelos clientes certos, no momento certo. Otimizamos seu site para os mecanismos de busca, criamos conteúdo relevante e de alta qualidade, e gerenciamos sua presença no Google Meu Negócio e diretórios locais. Monitoramos constantemente seu desempenho, ajustando estratégias para maximizar resultados e garantir um fluxo constante de visitantes qualificados para seu site.',
    image: presenceImage,
    slug: 'digital-presence'
  },
  */
  {
    id: 4,
    title: 'Mídia Indoor',
    description: 'Anuncie em locais estratégicos para alcançar novos públicos com impacto visual.',
    longDescription: 'Nossa solução de Mídia Indoor oferece uma forma inovadora de conectar sua marca com o público em ambientes físicos de alto tráfego. Posicionamos sua mensagem em displays digitais estrategicamente localizados em estabelecimentos comerciais, salas de espera, elevadores e outros espaços de grande circulação. Criamos conteúdo dinâmico e visualmente impactante que captura a atenção do público, aumenta o reconhecimento da marca e impulsiona as vendas. Com segmentação geográfica precisa e relatórios detalhados de performance, você maximiza o retorno sobre o investimento e alcança seu público-alvo de forma eficiente.',
    image: indoorMediaImage,
    slug: 'indoor-media'
  }
];

// Service options with pricing
export const serviceOptions: Record<string, ServiceOption[]> = {
  'automation': [
    {
      id: 1,
      title: 'Pacote Essencial',
      description: 'Chatbot básico para atendimento automático',
      price: 2000,
      maintenanceFee: 100,
      features: [
        'Instalação inicial: R$ 2.000',
        'Manutenção mensal: R$ 100',
        'Custos variáveis de acordo com o uso',
        'Chatbot básico no WhatsApp ou Telegram',
        'Respostas automáticas para perguntas frequentes',
        'Redirecionamento para redes sociais e site',
        'Redirecionamento para contato humano',
        'Redirecionamento para agendamento',
        'Configuração inicial e treinamento',
        'Suporte por e-mail'
      ]
    },
    {
      id: 2,
      title: 'Pacote Profissional',
      description: 'Solução completa com agendamento automático',
      price: 4000,
      maintenanceFee: 200,
      features: [
        'Instalação inicial: R$ 4.000',
        'Manutenção mensal: R$ 200',
        'Custos variáveis de acordo com o uso',
        'Todos os recursos do Pacote Essencial',
        'Integração com calendário e agendamento automático',
        'Confirmação automática de compromissos',
        'Personalização avançada de fluxos de conversa',
        'Relatórios de desempenho e uso',
        'Suporte prioritário',
        'Atualizações mensais de conteúdo'
      ]
    },
    {
      id: 3,
      title: 'Pacote Personalizado',
      description: 'Solução sob medida para necessidades específicas',
      price: null,
      maintenanceFee: null,
      features: [
        'Preço de instalação e manutenção sob consulta',
        'Todos os recursos do Pacote Profissional',
        'Integração com sistemas da empresa (CRM, ERP, etc.)',
        'Lembretes de horários pelo WhatsApp',
        'Automação de processos internos',
        'Fluxos de conversa complexos e personalizados',
        'Integrações com APIs de terceiros',
        'Gerente de projeto dedicado',
        'Desenvolvimento de funcionalidades exclusivas'
      ]
    }
  ],
  'social-media': [
    {
      id: 1,
      title: 'Pacote Essencial',
      description: 'Estabeleça sua presença online básica',
      price: 1000,
      features: [
        'Criação e otimização do perfil no Google Maps e Google Business',
        'Configuração inicial de redes sociais (Instagram, Facebook e TikTok)',
        '8 posts mensais com imagens e legendas otimizadas',
        'Relatório básico de métricas',
        'Suporte por e-mail'
      ]
    },
    {
      id: 2,
      title: 'Pacote Profissional',
      description: 'Gestão completa para empresas em crescimento',
      price: 2000,
      features: [
        'Gestão completa de redes sociais (Instagram, Facebook e TikTok)',
        '12 posts mensais + 4 stories semanais',
        'Produção de vídeos curtos para reels e TikTok',
        'Monitoramento de interações e respostas a mensagens diretas',
        'Criação de campanhas simples de tráfego pago (até R$ 500 de verba)',
        'Relatórios mensais detalhados',
        'Suporte prioritário'
      ]
    },
    {
      id: 3,
      title: 'Pacote Personalizado',
      description: 'Solução sob medida para suas necessidades específicas',
      price: null,
      features: [
        'Quantidade personalizada de posts e stories',
        'Inclusão de redes sociais adicionais conforme necessidade',
        'Opção de criação e gestão de site',
        'Campanhas avançadas de tráfego pago',
        'Produção de conteúdo especializado para seu nicho',
        'Estratégia de marketing digital completa',
        'Gerente de conta dedicado'
      ]
    }
  ],
  /* Opções do serviço de Presença Digital Otimizada temporariamente removidas
  'digital-presence': [
    {
      id: 1,
      title: 'Plano Local',
      description: 'Otimização básica para presença local',
      price: 1300,
      features: [
        'Otimização do Google Meu Negócio',
        'SEO on-page básico',
        'Cadastro em 10 diretórios locais',
        'Relatório mensal de posicionamento',
        'Suporte por e-mail'
      ]
    },
    {
      id: 2,
      title: 'Plano Completo',
      description: 'Otimização avançada para presença regional',
      price: 2600,
      features: [
        'Otimização completa do Google Meu Negócio',
        'SEO on-page e off-page',
        'Estratégia de link building',
        'Cadastro em 30 diretórios',
        'Otimização de conteúdo existente',
        'Relatórios quinzenais',
        'Suporte prioritário'
      ]
    },
    {
      id: 3,
      title: 'Plano Nacional',
      description: 'Estratégia completa para presença nacional',
      price: 4200,
      features: [
        'Estratégia SEO completa',
        'Criação de conteúdo otimizado',
        'Link building avançado',
        'Otimização técnica do site',
        'Análise de concorrentes',
        'Monitoramento de palavras-chave',
        'Dashboard em tempo real',
        'Gerente de projeto dedicado'
      ]
    }
  ],
  */
  'indoor-media': [
    {
      id: 1,
      title: 'Plano Inicial',
      description: 'Presença em locais selecionados',
      price: 1800,
      features: [
        'Exibição em 5 pontos estratégicos',
        '30 segundos de exibição a cada 10 minutos',
        'Design básico de anúncios',
        'Relatório mensal de impressões',
        'Duração: 1 mês'
      ]
    },
    {
      id: 2,
      title: 'Plano Expansão',
      description: 'Cobertura ampliada para maior visibilidade',
      price: 3200,
      features: [
        'Exibição em 15 pontos estratégicos',
        '45 segundos de exibição a cada 8 minutos',
        'Design personalizado de anúncios',
        'Segmentação por bairros',
        'Relatórios quinzenais',
        'Duração: 3 meses'
      ]
    },
    {
      id: 3,
      title: 'Plano Domínio',
      description: 'Cobertura completa para máxima exposição',
      price: 5500,
      features: [
        'Exibição em 30 pontos premium',
        '60 segundos de exibição a cada 5 minutos',
        'Design premium de anúncios',
        'Segmentação avançada',
        'Conteúdo interativo',
        'Relatórios semanais detalhados',
        'Duração: 6 meses'
      ]
    }
  ]
};

// Combo option with discount
export const comboOption: ComboOption = {
  id: 1,
  title: 'Pacote Completo Nyctea',
  description: 'Solução integrada com os melhores serviços da Nyctea Platform com desconto especial',
  price: 2500, // Preço mensal com desconto já aplicado
  originalPrice: 2968, // Preço original sem desconto
  installationPrice: 3400, // Preço de instalação com desconto
  originalInstallationPrice: 4000, // Preço de instalação original
  discountPercentage: 15, // 15% de desconto na instalação
  features: [
    'Economia de R$ 468 mensais em relação à contratação individual',
    'Desconto de 15% na instalação do chatbot',
    'Gerente de conta exclusivo',
    'Reuniões estratégicas mensais',
    'Relatórios integrados de performance',
    'Prioridade no atendimento',
    'Acesso antecipado a novos recursos',
    'Consultoria estratégica trimestral'
  ],
  includedServices: [
    'Automação Inteligente - Pacote Profissional',
    'Gestão de Redes Sociais - Pacote Profissional',
    'Mídia Indoor - Plano Domínio'
  ]
};

// Helper function to get service by slug
export const getServiceBySlug = (slug: string): Service | undefined => {
  return services.find(service => service.slug === slug);
};

// Helper function to get service options by slug
export const getServiceOptionsBySlug = (slug: string): ServiceOption[] => {
  return serviceOptions[slug] || [];
}; 