import React from 'react';
import { Link } from 'react-router-dom';
import { trackServiceInteraction } from '../../utils/analytics';

interface IndoorPricingPlan {
  id: number;
  title: string;
  description: string;
  spaces: number;
  monthlyPrice: number | null;
  yearlyPrice: number | null;
  features: string[];
  isPopular?: boolean;
  isCustom?: boolean;
}

const IndoorPricingTable: React.FC = () => {
  const [billingPeriod, setBillingPeriod] = React.useState<'monthly' | 'yearly'>('monthly');
  
  const pricingPlans: IndoorPricingPlan[] = [
    {
      id: 1,
      title: "Plano Flash",
      description: "Exposição básica para pequenos negócios",
      spaces: 1,
      monthlyPrice: 300,
      yearlyPrice: 150,
      features: [
        "1 espaço de exposição (15s a cada 1h)",
        "Relatório mensal de exposição",
        "Suporte por e-mail",
        "Possibilidade de alteração do anúncio mensalmente"
      ]
    },
    {
      id: 2,
      title: "Plano Spotlight",
      description: "Exposição ampliada para maior visibilidade",
      spaces: 2,
      monthlyPrice: 480,
      yearlyPrice: 240,
      features: [
        "2 espaços de exposição (30s a cada 1h)",
        "20% de desconto por espaço",
        "Relatórios quinzenais de exposição",
        "Design básico do anúncio incluso",
        "Suporte prioritário",
        "Possibilidade de alteração do anúncio quinzenalmente"
      ],
      isPopular: true
    },
    {
      id: 3,
      title: "Plano Domínio",
      description: "Exposição máxima para dominar o espaço",
      spaces: 4,
      monthlyPrice: 768,
      yearlyPrice: 384,
      features: [
        "4 espaços de exposição (60s a cada 1h)",
        "36% de desconto por espaço",
        "Relatórios semanais detalhados",
        "Design premium do anúncio incluso",
        "Suporte VIP com gerente dedicado",
        "Possibilidade de alteração do anúncio semanalmente"
      ]
    },
    {
      id: 4,
      title: "Plano Personalizado",
      description: "Solução sob medida para suas necessidades",
      spaces: 0,
      monthlyPrice: null,
      yearlyPrice: null,
      features: [
        "Número de espaços personalizado",
        "Descontos progressivos a partir de 5 espaços",
        "Opções de segmentação por horário",
        "Possibilidade de exclusividade em determinados períodos",
        "Campanhas sazonais ou por tempo limitado",
        "Integração com outras estratégias de marketing",
        "Relatórios personalizados conforme necessidade"
      ],
      isCustom: true
    }
  ];

  // Função para rastrear cliques nos botões de ação
  const handleClick = (planTitle: string, action: 'hire' | 'quote') => {
    trackServiceInteraction(
      'indoor-media',
      'Mídia Indoor',
      action === 'hire' ? `hire_option_${planTitle}` : `request_quote_${planTitle}`
    );
  };

  // Função para construir a URL de contato com parâmetros
  const buildContactUrl = (plan: IndoorPricingPlan, action: 'hire' | 'quote') => {
    const params = new URLSearchParams();
    params.append('service', 'Mídia Indoor');
    params.append('option', plan.title);
    params.append('action', action === 'hire' ? 'contratar' : 'proposta');
    return `/contact?${params.toString()}`;
  };

  // Função para obter o valor de espaços de exposição
  const getExposureSpaces = (plan: IndoorPricingPlan): string => {
    if (plan.spaces === 0) {
      return 'Personalizado';
    }
    return `${plan.spaces} espaço${plan.spaces > 1 ? 's' : ''} (${plan.spaces * 15}s a cada hora)`;
  };

  // Função para obter o valor de relatórios
  const getReportValue = (plan: IndoorPricingPlan): string => {
    if (plan.title === "Plano Flash") {
      return "Relatório mensal básico";
    } else if (plan.title === "Plano Spotlight") {
      return "Relatórios quinzenais detalhados";
    } else if (plan.title === "Plano Domínio") {
      return "Relatórios semanais com análise de impacto";
    } else {
      return "Relatórios personalizados";
    }
  };

  // Função para obter o valor de design
  const getDesignValue = (plan: IndoorPricingPlan): string => {
    if (plan.title === "Plano Flash") {
      return "Não incluído";
    } else if (plan.title === "Plano Spotlight") {
      return "Design básico incluído";
    } else if (plan.title === "Plano Domínio") {
      return "Design premium incluído";
    } else {
      return "Design personalizado";
    }
  };

  // Função para obter o valor de suporte
  const getSupportValue = (plan: IndoorPricingPlan): string => {
    if (plan.title === "Plano Flash") {
      return "Suporte por e-mail";
    } else if (plan.title === "Plano Spotlight") {
      return "Suporte prioritário";
    } else if (plan.title === "Plano Domínio") {
      return "Suporte VIP com gerente dedicado";
    } else {
      return "Suporte personalizado";
    }
  };

  // Função para obter o valor de alteração de anúncio
  const getChangeFrequencyValue = (plan: IndoorPricingPlan): string => {
    if (plan.title === "Plano Flash") {
      return "Mensal";
    } else if (plan.title === "Plano Spotlight") {
      return "Quinzenal";
    } else if (plan.title === "Plano Domínio") {
      return "Semanal";
    } else {
      return "Personalizado";
    }
  };

  // Função para obter o valor de segmentação
  const getSegmentationValue = (plan: IndoorPricingPlan): string => {
    if (plan.title === "Plano Flash" || plan.title === "Plano Spotlight") {
      return "Não incluído";
    } else if (plan.title === "Plano Domínio") {
      return "Segmentação básica por horário";
    } else {
      return "Segmentação avançada por horário e dia";
    }
  };

  // Função para obter o valor de desconto por espaço
  const getDiscountValue = (plan: IndoorPricingPlan): string => {
    if (billingPeriod === 'monthly') {
      if (plan.title === "Plano Flash") return "Sem desconto";
      if (plan.title === "Plano Spotlight") return "20% de desconto";
      if (plan.title === "Plano Domínio") return "36% de desconto";
      return "Descontos progressivos";
    } else {
      // Valores para plano anual
      if (plan.title === "Plano Flash") return "50% de desconto";
      if (plan.title === "Plano Spotlight") return "60% de desconto";
      if (plan.title === "Plano Domínio") return "68% de desconto";
      return "Descontos progressivos";
    }
  };

  return (
    <div>
      {/* Informação sobre descontos progressivos */}
      <div className="bg-primary-dark/5 p-4 rounded-lg mb-8 text-center">
        <h3 className="text-lg font-semibold text-primary-dark mb-2">Descontos Progressivos</h3>
        <p className="text-primary">
          Quanto maior o tempo de exposição, maior o desconto por espaço:
          <span className="inline-flex items-center gap-4 mt-2 flex-wrap justify-center">
            {billingPeriod === 'monthly' ? (
              <>
                <span className="inline-flex items-center">
                  <span className="bg-accent text-white text-xs font-medium px-2 py-1 rounded">1 espaço</span>
                  <span className="ml-2">Preço base</span>
                </span>
                <span className="inline-flex items-center">
                  <span className="bg-accent text-white text-xs font-medium px-2 py-1 rounded">2 espaços</span>
                  <span className="ml-2">20% de desconto por espaço</span>
                </span>
                <span className="inline-flex items-center">
                  <span className="bg-accent text-white text-xs font-medium px-2 py-1 rounded">4 espaços</span>
                  <span className="ml-2">36% de desconto por espaço</span>
                </span>
              </>
            ) : (
              <>
                <span className="inline-flex items-center">
                  <span className="bg-accent text-white text-xs font-medium px-2 py-1 rounded">1 espaço</span>
                  <span className="ml-2">50% de desconto</span>
                </span>
                <span className="inline-flex items-center">
                  <span className="bg-accent text-white text-xs font-medium px-2 py-1 rounded">2 espaços</span>
                  <span className="ml-2">60% de desconto por espaço</span>
                </span>
                <span className="inline-flex items-center">
                  <span className="bg-accent text-white text-xs font-medium px-2 py-1 rounded">4 espaços</span>
                  <span className="ml-2">68% de desconto por espaço</span>
                </span>
              </>
            )}
          </span>
        </p>
      </div>

      {/* Seletor de período */}
      <div className="flex justify-center mb-8">
        <div className="bg-background rounded-full p-1 inline-flex">
          <button
            onClick={() => setBillingPeriod('monthly')}
            className={`px-4 py-2 rounded-full text-sm font-medium ${
              billingPeriod === 'monthly'
                ? 'bg-accent text-white'
                : 'text-primary hover:bg-gray-100'
            }`}
          >
            Mensal
          </button>
          <button
            onClick={() => setBillingPeriod('yearly')}
            className={`px-4 py-2 rounded-full text-sm font-medium ${
              billingPeriod === 'yearly'
                ? 'bg-accent text-white'
                : 'text-primary hover:bg-gray-100'
            }`}
          >
            Anual (50% OFF)
          </button>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="w-full border-collapse">
          <thead>
            <tr className="bg-primary-dark text-background-light">
              <th className="p-4 text-left border-b border-background/10">Recursos</th>
              {pricingPlans.map((plan) => (
                <th key={plan.id} className="p-4 text-center border-b border-background/10">
                  <div className="font-bold text-xl mb-2">{plan.title}</div>
                  <div className="text-sm opacity-80">Ideal para {plan.description}</div>
                  {plan.isPopular && (
                    <div className="mt-2">
                      <span className="bg-accent text-white text-xs font-medium px-2 py-1 rounded">Mais Popular</span>
                    </div>
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {/* Preço */}
            <tr className="bg-background-light hover:bg-background-light/80">
              <td className="p-4 border-b border-primary/10 font-medium">Preço</td>
              {pricingPlans.map((plan) => (
                <td key={plan.id} className="p-4 text-center border-b border-primary/10">
                  <div className="font-bold text-xl text-primary-dark">
                    {plan.monthlyPrice === null 
                      ? 'Sob Consulta' 
                      : `R$ ${billingPeriod === 'monthly' ? plan.monthlyPrice : plan.yearlyPrice}`}
                  </div>
                  {plan.monthlyPrice !== null && (
                    <div className="text-sm text-primary-dark/70">
                      {billingPeriod === 'yearly' ? 'por mês no plano anual' : 'por mês'}
                    </div>
                  )}
                </td>
              ))}
            </tr>

            {/* Espaços de Exposição */}
            <tr className="bg-background hover:bg-background/90">
              <td className="p-4 border-b border-primary/10 font-medium">Espaços de Exposição</td>
              {pricingPlans.map((plan) => (
                <td key={plan.id} className="p-4 text-center border-b border-primary/10">
                  {getExposureSpaces(plan)}
                </td>
              ))}
            </tr>

            {/* Desconto por Espaço */}
            <tr className="bg-background-light hover:bg-background-light/80">
              <td className="p-4 border-b border-primary/10 font-medium">Desconto por Espaço</td>
              {pricingPlans.map((plan) => (
                <td key={plan.id} className="p-4 text-center border-b border-primary/10">
                  {getDiscountValue(plan)}
                </td>
              ))}
            </tr>

            {/* Relatórios */}
            <tr className="bg-background hover:bg-background/90">
              <td className="p-4 border-b border-primary/10 font-medium">Relatórios</td>
              {pricingPlans.map((plan) => (
                <td key={plan.id} className="p-4 text-center border-b border-primary/10">
                  {getReportValue(plan)}
                </td>
              ))}
            </tr>

            {/* Design de Anúncio */}
            <tr className="bg-background-light hover:bg-background-light/80">
              <td className="p-4 border-b border-primary/10 font-medium">Design de Anúncio</td>
              {pricingPlans.map((plan) => (
                <td key={plan.id} className="p-4 text-center border-b border-primary/10">
                  {getDesignValue(plan)}
                </td>
              ))}
            </tr>

            {/* Frequência de Alteração */}
            <tr className="bg-background hover:bg-background/90">
              <td className="p-4 border-b border-primary/10 font-medium">Frequência de Alteração</td>
              {pricingPlans.map((plan) => (
                <td key={plan.id} className="p-4 text-center border-b border-primary/10">
                  {getChangeFrequencyValue(plan)}
                </td>
              ))}
            </tr>

            {/* Segmentação por Horário */}
            <tr className="bg-background-light hover:bg-background-light/80">
              <td className="p-4 border-b border-primary/10 font-medium">Segmentação por Horário</td>
              {pricingPlans.map((plan) => (
                <td key={plan.id} className="p-4 text-center border-b border-primary/10">
                  {getSegmentationValue(plan)}
                </td>
              ))}
            </tr>

            {/* Suporte */}
            <tr className="bg-background hover:bg-background/90">
              <td className="p-4 border-b border-primary/10 font-medium">Suporte</td>
              {pricingPlans.map((plan) => (
                <td key={plan.id} className="p-4 text-center border-b border-primary/10">
                  {getSupportValue(plan)}
                </td>
              ))}
            </tr>

            {/* Botões de Ação */}
            <tr className="bg-primary-dark/5">
              <td className="p-4"></td>
              {pricingPlans.map((plan) => (
                <td key={plan.id} className="p-4 text-center">
                  <div className="flex flex-col space-y-3">
                    {plan.monthlyPrice !== null ? (
                      <Link 
                        to={buildContactUrl(plan, 'hire')}
                        className="btn-accent w-full py-2"
                        onClick={() => handleClick(plan.title, 'hire')}
                      >
                        Contratar Agora
                      </Link>
                    ) : (
                      <Link 
                        to={buildContactUrl(plan, 'quote')}
                        className="btn-accent w-full py-2"
                        onClick={() => handleClick(plan.title, 'quote')}
                      >
                        Solicitar Proposta
                      </Link>
                    )}
                  </div>
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
      
      {billingPeriod === 'yearly' && (
        <p className="text-sm text-gray-600 mt-4 text-center">
          O plano anual representa 50% de desconto em relação ao plano mensal.
        </p>
      )}
      
      <p className="text-sm text-gray-600 mt-4 text-center">
        Cada espaço corresponde a uma exposição de 15 segundos a cada hora na tela do Shopping Centro.
      </p>
    </div>
  );
};

export default IndoorPricingTable; 