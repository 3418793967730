import React from 'react';
import { Link } from 'react-router-dom';
import { BlogPost } from '../../types/blog';
import ImageWithFallback from '../common/ImageWithFallback';
import defaultFallbackImage from '../../assets/images/blog/default-fallback.jpg';
import MarkdownRenderer from './MarkdownRenderer';
import '../../styles/blog-content.css';

interface BlogTemplateProps {
  post: BlogPost;
}

const BlogTemplate: React.FC<BlogTemplateProps> = ({ post }) => {
  // Check if content is Markdown (doesn't start with HTML tags)
  const isMarkdown = !post.content.trim().startsWith('<');

  return (
    <div className="container mx-auto px-4 py-12">
      <div className="max-w-4xl mx-auto">
        <Link to="/blog" className="text-primary hover:text-accent mb-6 inline-flex items-center">
          <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 19l-7-7m0 0l7-7m-7 7h18"></path>
          </svg>
          Voltar para o Blog
        </Link>
        
        <article className="bg-background-light rounded-lg shadow-lg overflow-hidden">
          {post.coverImage && (
            <div className="w-full h-80 overflow-hidden">
              <ImageWithFallback 
                src={post.coverImage} 
                alt={post.title} 
                fallbackSrc={defaultFallbackImage}
                className="w-full h-full object-cover"
              />
            </div>
          )}
          
          <div className="p-8">
            <h1 className="text-3xl md:text-4xl font-bold text-primary-dark mb-4">{post.title}</h1>
            
            <div className="flex items-center text-sm text-gray-600 mb-6">
              <span className="mr-4">
                <svg className="w-4 h-4 inline mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"></path>
                </svg>
                {new Date(post.date).toLocaleDateString('pt-BR')}
              </span>
              
              {post.author && (
                <span className="mr-4">
                  <svg className="w-4 h-4 inline mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path>
                  </svg>
                  {post.author}
                </span>
              )}
              
              {post.curator && (
                <span>
                  <svg className="w-4 h-4 inline mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"></path>
                  </svg>
                  Curadoria: {post.curator}
                </span>
              )}
            </div>
            
            {post.tags && post.tags.length > 0 && (
              <div className="flex flex-wrap gap-2 mb-6">
                {post.tags.map((tag: string, index: number) => (
                  <span key={index} className="bg-primary/10 text-primary text-xs px-3 py-1 rounded-full">
                    {tag}
                  </span>
                ))}
              </div>
            )}
            
            <div className="blog-content">
              {isMarkdown ? (
                <MarkdownRenderer content={post.content} />
              ) : (
                <div dangerouslySetInnerHTML={{ __html: post.content }} />
              )}
            </div>
          </div>
        </article>
      </div>
    </div>
  );
};

export default BlogTemplate; 