/**
 * Utilitários para rastreamento de analytics e conversões
 */

// Tipos para os eventos do Google Ads
interface ConversionEvent {
  send_to: string;
  value?: number;
  currency?: string;
  transaction_id?: string;
}

interface CheckoutItem {
  id: string;
  name: string;
  category?: string;
  price: string | number;
  quantity: number;
}

interface CheckoutEvent {
  items: CheckoutItem[];
  value: number;
  currency: string;
}

/**
 * Tipos para eventos do Google Analytics
 */
interface PageViewParams {
  page_title: string;
  page_location: string;
  page_path: string;
}

interface EventParams {
  [key: string]: any;
}

/**
 * Verifica se o gtag está disponível
 */
const isGtagAvailable = (): boolean => {
  return typeof window !== 'undefined' && typeof (window as any).gtag === 'function';
};

/**
 * Rastreia um evento de conversão do Google Ads
 * @param conversionId - ID da conversão no formato 'AW-XXXXXXXXXX/YYYYYYYYYYYYYYY'
 * @param options - Opções adicionais para o evento de conversão
 */
export const trackConversion = (
  conversionId: string, 
  options?: Omit<ConversionEvent, 'send_to'>
) => {
  if (isGtagAvailable()) {
    try {
      (window as any).gtag('event', 'conversion', {
        send_to: conversionId,
        ...options
      });
    } catch (error) {
      console.error('Erro ao rastrear conversão:', error);
    }
  }
};

/**
 * Rastreia um evento de início de checkout
 * @param items - Itens incluídos no checkout
 * @param value - Valor total do checkout
 * @param currency - Moeda (padrão: 'BRL')
 */
export const trackBeginCheckout = (
  items: CheckoutItem[],
  value: number,
  currency: string = 'BRL'
) => {
  if (isGtagAvailable()) {
    try {
      (window as any).gtag('event', 'begin_checkout', {
        items,
        value,
        currency
      });
    } catch (error) {
      console.error('Erro ao rastrear início de checkout:', error);
    }
  }
};

/**
 * Rastreia um evento de compra do e-book
 * @param productId - ID do produto
 * @param productName - Nome do produto
 * @param price - Preço do produto
 */
export const trackEbookPurchase = (
  productId: string,
  productName: string,
  price: number
) => {
  try {
    // Rastreia a conversão
    trackConversion('AW-16917219212/Yx-DCKqLtZQZELnLnqU9', {
      value: price,
      currency: 'BRL'
    });
    
    // Rastreia o início do checkout para análises adicionais
    trackBeginCheckout(
      [
        {
          id: productId,
          name: productName,
          category: 'E-book',
          price: price,
          quantity: 1
        }
      ],
      price
    );
  } catch (error) {
    console.error('Erro ao rastrear compra de e-book:', error);
  }
};

/**
 * Rastreia uma visualização de página
 * @param params - Parâmetros da visualização de página
 */
export const trackPageView = (params: PageViewParams) => {
  if (isGtagAvailable()) {
    try {
      (window as any).gtag('event', 'page_view', params);
    } catch (error) {
      console.error('Erro ao rastrear visualização de página:', error);
    }
  }
};

/**
 * Rastreia um evento personalizado
 * @param eventName - Nome do evento
 * @param params - Parâmetros do evento
 */
export const trackEvent = (eventName: string, params?: EventParams) => {
  if (isGtagAvailable()) {
    try {
      (window as any).gtag('event', eventName, params || {});
    } catch (error) {
      console.error(`Erro ao rastrear evento ${eventName}:`, error);
    }
  }
};

/**
 * Rastreia um clique em link externo
 * @param url - URL do link
 * @param linkText - Texto do link
 * @param category - Categoria do link (ex: 'social', 'parceiro', etc)
 */
export const trackOutboundLink = (url: string, linkText: string, category: string = 'outbound') => {
  try {
    trackEvent('click_external_link', {
      link_url: url,
      link_text: linkText,
      link_category: category
    });
  } catch (error) {
    console.error('Erro ao rastrear clique em link externo:', error);
  }
};

/**
 * Rastreia engajamento com conteúdo de blog
 * @param blogId - ID ou slug do blog
 * @param blogTitle - Título do blog
 * @param action - Ação realizada (ex: 'read', 'share', 'comment')
 */
export const trackBlogEngagement = (blogId: string, blogTitle: string, action: string) => {
  try {
    trackEvent('blog_engagement', {
      blog_id: blogId,
      blog_title: blogTitle,
      action: action
    });
  } catch (error) {
    console.error('Erro ao rastrear engajamento com blog:', error);
  }
};

/**
 * Rastreia interação com serviço
 * @param serviceId - ID do serviço
 * @param serviceName - Nome do serviço
 * @param action - Ação realizada (ex: 'view', 'inquiry', 'request_quote')
 */
export const trackServiceInteraction = (serviceId: string, serviceName: string, action: string) => {
  try {
    trackEvent('service_interaction', {
      service_id: serviceId,
      service_name: serviceName,
      action: action
    });
  } catch (error) {
    console.error('Erro ao rastrear interação com serviço:', error);
  }
};

/**
 * Rastreia rolagem da página
 * @param depth - Profundidade da rolagem (25, 50, 75, 100)
 * @param pageTitle - Título da página
 */
export const trackScrollDepth = (depth: number, pageTitle: string) => {
  try {
    trackEvent('scroll_depth', {
      depth: depth,
      page_title: pageTitle
    });
  } catch (error) {
    console.error('Erro ao rastrear profundidade de rolagem:', error);
  }
}; 