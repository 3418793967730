import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { getServiceBySlug } from '../../data/servicesData';
import IndoorPricingTable from '../../components/services/IndoorPricingTable';
import LocationInfo from '../../components/services/LocationInfo';
import PartnershipInfo from '../../components/services/PartnershipInfo';
import FAQSection from '../../components/services/FAQSection';
import { trackServiceInteraction } from '../../utils/analytics';
import ServiceNavigation from '../../components/services/ServiceNavigation';
import SEO from '../../components/common/SEO';

// Imagens para a mídia indoor
import screenImage from '../../assets/images/services_get_energy_midea.jpg';
import mapImage from '../../assets/images/services_mapa.png';
import partnerLogo from '../../assets/images/logo_get_energy.png';

// Interface para os benefícios da mídia indoor
interface MediaBenefit {
  title: string;
  description: string;
  icon: React.ReactNode;
}

const IndoorMedia: React.FC = () => {
  const service = getServiceBySlug('indoor-media');
  const pricingSectionRef = useRef<HTMLElement>(null);
  const faqSectionRef = useRef<HTMLElement>(null);

  if (!service) {
    return <div>Serviço não encontrado</div>;
  }

  // Função para rolar suavemente até a seção de preços
  const scrollToPricing = () => {
    try {
      // Rastreia o clique no botão "Ver Planos"
      trackServiceInteraction(service.slug, service.title, 'view_pricing');
      
      // Rola suavemente até a seção de preços
      pricingSectionRef.current?.scrollIntoView({ 
        behavior: 'smooth',
        block: 'start'
      });
    } catch (error) {
      console.error('Erro ao rolar para a seção de preços:', error);
    }
  };

  // Função para rolar suavemente até a seção de FAQ
  const scrollToFAQ = () => {
    try {
      // Rastreia o clique no botão "Dúvidas Frequentes"
      trackServiceInteraction(service.slug, service.title, 'view_faq');
      
      // Rola suavemente até a seção de FAQ
      faqSectionRef.current?.scrollIntoView({ 
        behavior: 'smooth',
        block: 'start'
      });
    } catch (error) {
      console.error('Erro ao rolar para a seção de FAQ:', error);
    }
  };

  // Benefícios da mídia indoor
  const mediaBenefits: MediaBenefit[] = [
    {
      title: "Alto Impacto Visual",
      description: "Displays digitais de alta resolução que capturam a atenção do público em ambientes de espera e alta circulação.",
      icon: (
        <svg className="w-10 h-10 text-accent" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path>
        </svg>
      )
    },
    {
      title: "Público Qualificado",
      description: "Alcance pessoas com poder de compra em momentos de decisão, aumentando a eficácia da sua mensagem.",
      icon: (
        <svg className="w-10 h-10 text-accent" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"></path>
        </svg>
      )
    },
    {
      title: "Segmentação Geográfica",
      description: "Direcione sua comunicação para áreas específicas, otimizando o investimento e aumentando a relevância.",
      icon: (
        <svg className="w-10 h-10 text-accent" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"></path>
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"></path>
        </svg>
      )
    },
    {
      title: "Flexibilidade de Conteúdo",
      description: "Atualize seu conteúdo rapidamente, teste diferentes mensagens e adapte-se às tendências do mercado.",
      icon: (
        <svg className="w-10 h-10 text-accent" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"></path>
        </svg>
      )
    }
  ];

  // Dados para a seção de FAQ
  const faqs = [
    {
      question: "Como funciona a exibição dos anúncios na mídia indoor?",
      answer: "Cada espaço contratado corresponde a uma exibição de 15 segundos a cada hora no circuito de telas digitais. Seu anúncio será exibido em rotação com outros anunciantes, garantindo visibilidade constante ao longo do dia. As telas estão estrategicamente posicionadas em áreas de alto fluxo e espera, maximizando o impacto da sua mensagem."
    },
    {
      question: "Quais formatos de arquivo são aceitos para os anúncios?",
      answer: "Aceitamos arquivos nos formatos JPG, PNG e MP4 (para vídeos). As dimensões ideais são 1080x1920 pixels (formato vertical). Caso não tenha o material pronto, oferecemos serviços de design gráfico para criar anúncios impactantes que se adequem perfeitamente às nossas telas."
    },
    {
      question: "Qual é o público alcançado pela mídia indoor no Shopping Centro?",
      answer: "O Shopping Centro em São José dos Campos recebe aproximadamente 80.000 visitantes por mês, com perfil predominante das classes B e C. O público é diversificado, incluindo famílias, profissionais e estudantes, com idade média entre 20 e 55 anos e poder aquisitivo médio."
    },
    {
      question: "É possível segmentar a exibição por horários específicos?",
      answer: "Não, o anúncio é exibido ao longo de todo o dia no horário de funcionamento do shopping (9h-19h em dias de semana e 9h-17h aos sábados). Isso garante que sua mensagem alcance o público durante todo o período de circulação, maximizando a exposição da sua marca."
    },
    {
      question: "Quanto tempo leva para meu anúncio começar a ser exibido após a contratação?",
      answer: "Após a aprovação do material, seu anúncio estará no ar em até 48 horas úteis. O processo inclui a verificação técnica do material, programação no sistema e confirmação da exibição. Fornecemos relatórios periódicos de veiculação para que você possa acompanhar o desempenho da sua campanha."
    }
  ];

  // Structured data para o serviço
  const serviceSchema = {
    "@context": "https://schema.org",
    "@type": "Service",
    "name": service.title,
    "description": service.description,
    "provider": {
      "@type": "Organization",
      "name": "Nyctea Platform"
    }
  };

  return (
    <>
      <SEO 
        title={`${service.title} | Nyctea Platform`}
        description={service.description}
        canonical={`/services/${service.slug}`}
        structuredData={serviceSchema}
      />
      
      {/* Navegação lateral entre serviços */}
      <ServiceNavigation currentServiceSlug={service.slug} />
      
      <div>
        {/* Hero Section */}
        <section className="bg-primary-dark py-16">
          <div className="container mx-auto px-4">
            <div className="flex flex-col md:flex-row items-center">
              <div className="md:w-1/2 mb-8 md:mb-0">
                <div className="text-left">
                  <Link to="/services" className="text-background hover:text-accent mb-4 inline-flex items-center">
                    <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 19l-7-7m0 0l7-7m-7 7h18"></path>
                    </svg>
                    Voltar para Serviços
                  </Link>
                  <h1 className="text-4xl font-bold text-background-light mb-4">{service.title}</h1>
                  <p className="text-xl text-background mb-6">Aumente a visibilidade da sua marca com anúncios digitais em locais estratégicos de alto fluxo. Impacte seu público-alvo no momento certo com mensagens visualmente atraentes.</p>
                  <div className="flex flex-wrap gap-4">
                    <button 
                      onClick={scrollToPricing}
                      className="btn-accent text-lg px-8 py-3 inline-block"
                    >
                      Ver Planos
                    </button>
                    <button 
                      onClick={scrollToFAQ}
                      className="btn-outline-accent text-lg px-8 py-3 inline-block"
                    >
                      Dúvidas Frequentes
                    </button>
                  </div>
                </div>
              </div>
              <div className="md:w-1/2">
                <div className="bg-background-light p-6 rounded-lg">
                  <img 
                    src={screenImage} 
                    alt={service.title} 
                    className="w-full h-auto object-contain rounded-lg"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Description Section */}
        <section className="py-16 bg-background">
          <div className="container mx-auto px-4">
            <div className="max-w-3xl mx-auto">
              <h2 className="text-3xl font-bold text-primary-dark mb-6">Sobre {service.title}</h2>
              <div className="prose prose-lg text-primary mb-8">
                <p>{service.longDescription}</p>
                <p>Nossa solução de mídia indoor no Shopping Centro de São José dos Campos oferece uma oportunidade única de conectar sua marca com milhares de consumidores diariamente. Com telas digitais de alta resolução estrategicamente posicionadas em áreas de grande circulação, seu anúncio ganha destaque e impacto visual, aumentando o reconhecimento da marca e influenciando decisões de compra.</p>
              </div>
              
              <div className="bg-primary-dark/5 p-6 rounded-lg mb-8">
                <h3 className="text-xl font-semibold text-primary-dark mb-4">Detalhes da Exposição</h3>
                <ul className="space-y-2">
                  <li className="flex items-start">
                    <svg className="h-5 w-5 text-accent mt-0.5 mr-2 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                    <span>Cada espaço corresponde a uma exposição de 15s a cada 1h</span>
                  </li>
                  <li className="flex items-start">
                    <svg className="h-5 w-5 text-accent mt-0.5 mr-2 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                    <span>Descontos progressivos conforme mais espaços são adquiridos</span>
                  </li>
                  <li className="flex items-start">
                    <svg className="h-5 w-5 text-accent mt-0.5 mr-2 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                    <span>Planos anuais com 50% de desconto</span>
                  </li>
                  <li className="flex items-start">
                    <svg className="h-5 w-5 text-accent mt-0.5 mr-2 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                    <span>Alto fluxo de pessoas no Shopping Centro em São José dos Campos</span>
                  </li>
                  <li className="flex items-start">
                    <svg className="h-5 w-5 text-accent mt-0.5 mr-2 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                    <span>Aproximadamente 80.000 visitantes por mês no shopping</span>
                  </li>
                  <li className="flex items-start">
                    <svg className="h-5 w-5 text-accent mt-0.5 mr-2 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                    <span>Suporte para criação de conteúdo visual impactante</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        {/* Benefits Section */}
        <section className="py-16 bg-background-light">
          <div className="container mx-auto px-4">
            <div className="max-w-5xl mx-auto">
              <div className="text-center mb-12">
                <h2 className="text-3xl font-bold text-primary-dark mb-4">Benefícios da Mídia Indoor</h2>
                <p className="text-xl text-primary max-w-2xl mx-auto">
                  Descubra como a mídia indoor pode impulsionar sua marca e aumentar seu alcance
                </p>
              </div>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                {mediaBenefits.map((benefit, index) => (
                  <div key={index} className="bg-background p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
                    <div className="flex items-center mb-4">
                      <div className="mr-4">
                        {benefit.icon}
                      </div>
                      <h3 className="text-xl font-bold text-primary-dark">{benefit.title}</h3>
                    </div>
                    <p className="text-primary">{benefit.description}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>

        {/* Pricing Section */}
        <section ref={pricingSectionRef} id="pricing" className="py-16 bg-background">
          <div className="container mx-auto px-4">
            <div className="max-w-5xl mx-auto">
              <div className="text-center mb-12">
                <h2 className="text-3xl font-bold text-primary-dark mb-4">Nossos Planos</h2>
                <p className="text-xl text-primary max-w-2xl mx-auto">
                  Escolha o plano que melhor se adapta às necessidades do seu negócio
                </p>
              </div>
              
              <IndoorPricingTable />
              
              <div className="mt-12 text-center">
                <p className="text-primary mb-6">
                  Entre em contato para discutir opções personalizadas ou para reservar seus espaços.
                </p>
                <Link to="/contact" className="btn-accent text-lg px-8 py-3">
                  Fale Conosco
                </Link>
              </div>
            </div>
          </div>
        </section>

        {/* Location Section */}
        <section className="py-16 bg-background-light">
          <div className="container mx-auto px-4">
            <div className="max-w-4xl mx-auto">
              <div className="text-center mb-12">
                <h2 className="text-3xl font-bold text-primary-dark mb-4">Localização</h2>
                <p className="text-xl text-primary max-w-2xl mx-auto">
                  Nossa tela expositora está estrategicamente posicionada no Shopping Centro em São José dos Campos
                </p>
              </div>
              
              <LocationInfo 
                title="Shopping Centro - São José dos Campos"
                address="Rua Rubião Júnior, 84 - Centro, São José dos Campos - SP, 12210-180"
                mapImageSrc={mapImage}
                locationImageSrc={screenImage}
              />
            </div>
          </div>
        </section>

        {/* Partnership Section */}
        <section className="py-16 bg-background">
          <div className="container mx-auto px-4">
            <div className="max-w-4xl mx-auto">
              <div className="text-center mb-12">
                <h2 className="text-3xl font-bold text-primary-dark mb-4">Nossa Parceria</h2>
                <p className="text-xl text-primary max-w-2xl mx-auto">
                  Conheça nosso parceiro que torna possível a mídia indoor
                </p>
              </div>
              
              <PartnershipInfo 
                partnerName="GetEnergy"
                partnerDescription="A GetEnergy é nossa parceira exclusiva que disponibiliza mídia indoor e carregadores portáteis em locais estratégicos. Com tecnologia de ponta e ampla cobertura, a GetEnergy garante que sua marca seja vista por milhares de pessoas diariamente em ambientes de alto fluxo."
                logoSrc={partnerLogo}
              />
            </div>
          </div>
        </section>

        {/* FAQ Section */}
        <section ref={faqSectionRef} id="faq" className="py-16 bg-background-light">
          <div className="container mx-auto px-4">
            <div className="max-w-3xl mx-auto">
              <div className="text-center mb-12">
                <h2 className="text-3xl font-bold text-primary-dark mb-4">Perguntas Frequentes</h2>
                <p className="text-xl text-primary max-w-2xl mx-auto">
                  Respostas para as dúvidas mais comuns sobre nossa mídia indoor
                </p>
              </div>
              
              <FAQSection faqs={faqs} />
            </div>
          </div>
        </section>

        {/* CTA Section */}
        <section className="bg-primary-dark py-16">
          <div className="container mx-auto px-4 text-center">
            <h2 className="text-3xl font-bold text-background-light mb-6">
              Pronto para aumentar a visibilidade da sua marca?
            </h2>
            <p className="text-xl text-background mb-8 max-w-2xl mx-auto">
              Entre em contato hoje mesmo para discutir como nossa mídia indoor pode ajudar seu negócio a alcançar novos clientes.
            </p>
            <div className="flex flex-wrap justify-center gap-4">
              <Link to="/contact?service=Mídia%20Indoor&action=consultation" className="btn-accent text-lg px-8 py-3">
                Agendar Consultoria
              </Link>
              <Link to="/contact" className="btn-outline-accent text-lg px-8 py-3">
                Falar com Especialista
              </Link>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default IndoorMedia; 