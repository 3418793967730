import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

// Layout
import Layout from './components/layout/Layout';

// Pages
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Contact from './pages/Contact';
import Blog from './pages/Blog';
import BlogPost from './pages/BlogPost';
import Ebooks from './pages/Ebooks';

// Service Pages
import Automation from './pages/services/Automation';
import SocialMedia from './pages/services/SocialMedia';
import DigitalPresence from './pages/services/DigitalPresence';
import IndoorMedia from './pages/services/IndoorMedia';
import Combo from './pages/services/Combo';

function App() {
  return (
    <HelmetProvider>
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/contact" element={<Contact />} />
            
            {/* Blog Routes */}
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:slug" element={<BlogPost />} />
            
            {/* Ebooks Route */}
            <Route path="/ebooks" element={<Ebooks />} />
            
            {/* Service Routes */}
            <Route path="/services/automation" element={<Automation />} />
            <Route path="/services/social-media" element={<SocialMedia />} />
            {/* Rota temporariamente desativada
            <Route path="/services/digital-presence" element={<DigitalPresence />} />
            */}
            <Route path="/services/indoor-media" element={<IndoorMedia />} />
            <Route path="/services/combo" element={<Combo />} />
          </Routes>
        </Layout>
      </Router>
    </HelmetProvider>
  );
}

export default App;
