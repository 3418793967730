import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { getServiceBySlug } from '../../data/servicesData';
import AutomationPricingTable from '../../components/services/AutomationPricingTable';
import UseCaseInfo from '../../components/services/UseCaseInfo';
import FAQSection from '../../components/services/FAQSection';
import ProcessSection from '../../components/services/ProcessSection';
import { trackServiceInteraction } from '../../utils/analytics';
import ServiceNavigation from '../../components/services/ServiceNavigation';
import SEO from '../../components/common/SEO';

// Imagem para a página de automação
import automationImage from '../../assets/images/home_services_automation.png';
// Você pode adicionar mais imagens conforme necessário

const Automation: React.FC = () => {
  const service = getServiceBySlug('automation');
  const pricingSectionRef = useRef<HTMLElement>(null);
  const faqSectionRef = useRef<HTMLElement>(null);

  if (!service) {
    return <div>Serviço não encontrado</div>;
  }

  // Função para rolar suavemente até a seção de preços
  const scrollToPricing = () => {
    try {
      // Rastreia o clique no botão "Ver Planos"
      trackServiceInteraction(service.slug, service.title, 'view_pricing');
      
      // Rola suavemente até a seção de preços
      pricingSectionRef.current?.scrollIntoView({ 
        behavior: 'smooth',
        block: 'start'
      });
    } catch (error) {
      console.error('Erro ao rolar para a seção de preços:', error);
    }
  };

  // Função para rolar suavemente até a seção de FAQ
  const scrollToFAQ = () => {
    try {
      // Rastreia o clique no botão "Dúvidas Frequentes"
      trackServiceInteraction(service.slug, service.title, 'view_faq');
      
      // Rola suavemente até a seção de FAQ
      faqSectionRef.current?.scrollIntoView({ 
        behavior: 'smooth',
        block: 'start'
      });
    } catch (error) {
      console.error('Erro ao rolar para a seção de FAQ:', error);
    }
  };

  // Dados para a seção de casos de uso
  const useCases = [
    {
      title: "Chatbot para Agendamentos",
      problem: "Funcionários gastando horas por dia apenas marcando e remarcando consultas.",
      solution: [
        "Clientes verificam horários disponíveis em tempo real",
        "Marcam consultas automaticamente sem intervenção humana",
        "Recebem lembretes automáticos 24h antes do compromisso",
        "Cancelam ou remarcam sem precisar ligar",
        "Recebem instruções de preparação ou localização automaticamente"
      ],
      result: "Redução de 85% no tempo dedicado a agendamentos e diminuição de 60% nas faltas.",
      icon: "calendar"
    },
    {
      title: "Assistente de Dúvidas Frequentes",
      problem: "Equipe sobrecarregada respondendo as mesmas perguntas repetidamente.",
      solution: [
        "Responde instantaneamente às dúvidas mais comuns",
        "Envia links para páginas específicas do site ou blog",
        "Compartilha endereços e horários de funcionamento",
        "Envia cardápios, catálogos ou listas de preços",
        "Direciona para um atendente humano apenas quando necessário"
      ],
      result: "Redução de 70% nas mensagens que exigem resposta humana e aumento de 40% na satisfação dos clientes.",
      icon: "faq"
    },
    {
      title: "Automação de Marketing e Vendas",
      problem: "Dificuldade em nutrir leads e manter relacionamento consistente.",
      solution: [
        "Envia automaticamente materiais educativos após primeiro contato",
        "Segmenta clientes com base em interações anteriores",
        "Programa envio de promoções personalizadas",
        "Acompanha o cliente após a compra com dicas de uso",
        "Solicita avaliações e feedback em momentos estratégicos"
      ],
      result: "Aumento de 35% na taxa de conversão e 28% no valor médio de compra.",
      icon: "chat"
    }
  ];

  // Dados para a seção de processo
  const processSteps = [
    {
      title: "Análise e Planejamento",
      description: "Estudamos seus processos atuais, identificamos gargalos e oportunidades de automação. Definimos objetivos claros e métricas de sucesso.",
      icon: "analysis"
    },
    {
      title: "Desenvolvimento Personalizado",
      description: "Criamos fluxos de automação específicos para seu negócio e integramos com seus sistemas existentes. Desenvolvemos a personalidade e capacidades do seu chatbot.",
      icon: "development"
    },
    {
      title: "Implementação e Testes",
      description: "Implementamos a solução em ambiente controlado, realizamos testes extensivos e ajustes finos para garantir funcionamento perfeito.",
      icon: "testing"
    },
    {
      title: "Treinamento e Lançamento",
      description: "Treinamos sua equipe para gerenciar e monitorar as automações. Lançamos a solução gradualmente para garantir transição suave.",
      icon: "training"
    },
    {
      title: "Monitoramento e Evolução",
      description: "Acompanhamos o desempenho, coletamos feedback e implementamos melhorias contínuas para maximizar resultados.",
      icon: "monitoring"
    }
  ];

  // Dados para a seção de FAQ
  const faqs = [
    {
      question: "Quanto tempo leva para implementar a solução?",
      answer: "O tempo de implementação varia conforme a complexidade. Soluções básicas podem ser implementadas em 1-2 semanas, enquanto projetos mais complexos podem levar de 4-8 semanas. Durante nossa análise inicial, forneceremos um cronograma detalhado para seu caso específico."
    },
    {
      question: "Preciso ter conhecimento técnico para gerenciar o chatbot?",
      answer: "Não. Desenvolvemos interfaces amigáveis para que você possa gerenciar conteúdos e visualizar relatórios sem conhecimento técnico. Além disso, oferecemos treinamento completo para sua equipe e suporte contínuo para garantir que você se sinta confortável com a solução."
    },
    {
      question: "É possível integrar com meu sistema atual?",
      answer: "Sim. Nossa plataforma permite integração com praticamente qualquer sistema que possua API, incluindo sistemas de gestão, agendamento, CRMs e ferramentas de marketing. Se você já utiliza algum software específico para seu negócio, podemos avaliar a integração durante a fase de análise."
    },
    {
      question: "Como é calculado o custo variável de uso?",
      answer: "O custo variável é baseado no volume de mensagens processadas e no uso de recursos. Fornecemos estimativas claras baseadas no seu volume esperado e alertas quando você se aproxima de limites. Trabalhamos com pacotes que se adequam a diferentes portes de negócio, garantindo previsibilidade nos custos."
    },
    {
      question: "O que acontece se o chatbot não souber responder uma pergunta?",
      answer: "Nossos chatbots são programados para reconhecer quando não podem responder adequadamente e transferir a conversa para um atendente humano, garantindo que o cliente sempre receba a melhor assistência. Você também pode configurar respostas padrão para situações específicas."
    },
    {
      question: "Posso personalizar as mensagens e o tom de comunicação do chatbot?",
      answer: "Absolutamente! Personalizamos completamente o tom, linguagem e personalidade do chatbot para refletir a identidade da sua marca. Você pode revisar e aprovar todas as mensagens antes do lançamento."
    }
  ];

  // Structured data para o serviço
  const serviceSchema = {
    "@context": "https://schema.org",
    "@type": "Service",
    "name": service.title,
    "description": service.description,
    "provider": {
      "@type": "Organization",
      "name": "Nyctea Platform"
    }
  };

  return (
    <>
      <SEO 
        title={`${service.title} | Nyctea Platform`}
        description={service.description}
        canonical={`/services/${service.slug}`}
        structuredData={serviceSchema}
      />
      
      {/* Navegação lateral entre serviços */}
      <ServiceNavigation currentServiceSlug={service.slug} />
      
      <div>
        {/* Hero Section */}
        <section className="bg-primary-dark py-16">
          <div className="container mx-auto px-4">
            <div className="flex flex-col md:flex-row items-center">
              <div className="md:w-1/2 mb-8 md:mb-0">
                <div className="text-left">
                  <Link to="/services" className="text-background hover:text-accent mb-4 inline-flex items-center">
                    <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 19l-7-7m0 0l7-7m-7 7h18"></path>
                    </svg>
                    Voltar para Serviços
                  </Link>
                  <h1 className="text-4xl font-bold text-background-light mb-4">{service.title}</h1>
                  <p className="text-xl text-background mb-6">Transforme seu atendimento com chatbots inteligentes que conectam WhatsApp, agenda e outros sistemas. Reduza custos, aumente a satisfação dos clientes e libere sua equipe para o que realmente importa.</p>
                  <div className="flex flex-wrap gap-4">
                    <button 
                      onClick={scrollToPricing}
                      className="btn-accent text-lg px-8 py-3 inline-block"
                    >
                      Ver Planos
                    </button>
                    <button 
                      onClick={scrollToFAQ}
                      className="btn-outline-accent text-lg px-8 py-3 inline-block"
                    >
                      Dúvidas Frequentes
                    </button>
                  </div>
                </div>
              </div>
              <div className="md:w-1/2">
                <div className="bg-background-light p-6 rounded-lg">
                  <img 
                    src={automationImage} 
                    alt={service.title} 
                    className="w-full h-auto object-contain rounded-lg"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Description Section */}
        <section className="py-16 bg-background">
          <div className="container mx-auto px-4">
            <div className="max-w-3xl mx-auto">
              <h2 className="text-3xl font-bold text-primary-dark mb-6">Sobre {service.title}</h2>
              <div className="prose prose-lg text-primary mb-8">
                <p>Nossa solução de Automação Inteligente transforma a maneira como sua empresa interage com os clientes. Desenvolvemos chatbots personalizados que atendem 24 horas por dia no WhatsApp, respondendo dúvidas, agendando compromissos e enviando informações importantes.</p>
                <p>Nossos chatbots vão além de simples respostas automáticas. Eles compreendem o contexto das conversas, respondem a perguntas complexas e executam ações como agendamentos, envio de informações e redirecionamento inteligente.</p>
              </div>
              
              <div className="bg-primary-dark/5 p-6 rounded-lg mb-8">
                <h3 className="text-xl font-semibold text-primary-dark mb-4">Benefícios para seu Negócio</h3>
                <ul className="space-y-2">
                  <li className="flex items-start">
                    <svg className="h-5 w-5 text-accent mt-0.5 mr-2 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                    <span>Redução de até 70% no tempo gasto com tarefas repetitivas</span>
                  </li>
                  <li className="flex items-start">
                    <svg className="h-5 w-5 text-accent mt-0.5 mr-2 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                    <span>Atendimento 24/7 sem aumentar custos com pessoal</span>
                  </li>
                  <li className="flex items-start">
                    <svg className="h-5 w-5 text-accent mt-0.5 mr-2 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                    <span>Respostas instantâneas para seus clientes, sem tempo de espera</span>
                  </li>
                  <li className="flex items-start">
                    <svg className="h-5 w-5 text-accent mt-0.5 mr-2 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                    <span>Diminuição significativa de faltas em compromissos agendados</span>
                  </li>
                  <li className="flex items-start">
                    <svg className="h-5 w-5 text-accent mt-0.5 mr-2 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                    <span>Integração com seus sistemas existentes sem mudanças drásticas</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        {/* Use Cases Section */}
        <section className="py-16 bg-background-light">
          <div className="container mx-auto px-4">
            <div className="max-w-5xl mx-auto">
              <div className="text-center mb-12">
                <h2 className="text-3xl font-bold text-primary-dark mb-4">Soluções para seu Negócio</h2>
                <p className="text-xl text-primary max-w-2xl mx-auto">
                  Veja como nossos chatbots podem resolver problemas reais do seu dia a dia
                </p>
              </div>
              
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                {useCases.map((useCase, index) => (
                  <UseCaseInfo 
                    key={index}
                    title={useCase.title}
                    problem={useCase.problem}
                    solution={useCase.solution}
                    result={useCase.result}
                    icon={useCase.icon}
                  />
                ))}
              </div>
            </div>
          </div>
        </section>

        {/* Process Section */}
        <section className="py-16 bg-background">
          <div className="container mx-auto px-4">
            <div className="max-w-4xl mx-auto">
              <div className="text-center mb-12">
                <h2 className="text-3xl font-bold text-primary-dark mb-4">Como Funciona</h2>
                <p className="text-xl text-primary max-w-2xl mx-auto">
                  Nosso processo passo a passo para implementar sua solução de automação
                </p>
              </div>
              
              <ProcessSection steps={processSteps} />
            </div>
          </div>
        </section>

        {/* Pricing Section */}
        <section ref={pricingSectionRef} id="pricing" className="py-16 bg-background-light">
          <div className="container mx-auto px-4">
            <div className="max-w-5xl mx-auto">
              <div className="text-center mb-12">
                <h2 className="text-3xl font-bold text-primary-dark mb-4">Nossos Planos</h2>
                <p className="text-xl text-primary max-w-2xl mx-auto">
                  Escolha o plano que melhor se adapta às necessidades do seu negócio
                </p>
              </div>
              
              <AutomationPricingTable />
              
              <div className="mt-12 text-center">
                <p className="text-primary mb-6">
                  Entre em contato para discutir opções personalizadas ou para agendar uma demonstração.
                </p>
                <Link to="/contact" className="btn-accent text-lg px-8 py-3">
                  Fale Conosco
                </Link>
              </div>
            </div>
          </div>
        </section>

        {/* FAQ Section */}
        <section ref={faqSectionRef} id="faq" className="py-16 bg-background">
          <div className="container mx-auto px-4">
            <div className="max-w-3xl mx-auto">
              <div className="text-center mb-12">
                <h2 className="text-3xl font-bold text-primary-dark mb-4">Perguntas Frequentes</h2>
                <p className="text-xl text-primary max-w-2xl mx-auto">
                  Respostas para as dúvidas mais comuns sobre nossa solução de automação
                </p>
              </div>
              
              <FAQSection faqs={faqs} />
            </div>
          </div>
        </section>

        {/* CTA Section */}
        <section className="bg-primary-dark py-16">
          <div className="container mx-auto px-4 text-center">
            <h2 className="text-3xl font-bold text-background-light mb-6">
              Pronto para automatizar seu negócio?
            </h2>
            <p className="text-xl text-background mb-8 max-w-2xl mx-auto">
              Agende uma demonstração gratuita e descubra como nossa solução de Automação Inteligente pode transformar seus processos, reduzir custos e melhorar a experiência dos seus clientes.
            </p>
            <div className="flex flex-wrap justify-center gap-4">
              <Link to="/contact?service=Automação%20Inteligente&action=demo" className="btn-accent text-lg px-8 py-3">
                Agendar Demonstração
              </Link>
              <Link to="/contact" className="btn-outline-accent text-lg px-8 py-3">
                Falar com Consultor
              </Link>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Automation; 