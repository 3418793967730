import React from 'react';
import { Link } from 'react-router-dom';
import { getServiceOptionsBySlug } from '../../data/servicesData';
import { trackServiceInteraction } from '../../utils/analytics';

const AutomationPricingTable: React.FC = () => {
  const options = getServiceOptionsBySlug('automation');
  
  // Rastreia quando o usuário clica em "Contratar Agora" ou "Solicitar Proposta"
  const handleClick = (optionTitle: string, action: string) => {
    try {
      trackServiceInteraction(
        'automation',
        'Automação Inteligente',
        `${action}_${optionTitle.toLowerCase().replace(/\s+/g, '_')}`
      );
    } catch (error) {
      console.error('Erro ao rastrear interação com serviço:', error);
    }
  };

  // Constrói a URL para o formulário de contato com parâmetros de consulta
  const buildContactUrl = (optionTitle: string, price: number | null) => {
    const params = new URLSearchParams();
    
    params.append('service', 'Automação Inteligente');
    params.append('plan', optionTitle);
    
    if (price !== null) {
      params.append('price', price.toString());
      params.append('action', 'contract');
    } else {
      params.append('action', 'quote');
    }
    
    return `/contact?${params.toString()}`;
  };

  if (!options || options.length === 0) {
    return <div>Opções de serviço não encontradas</div>;
  }

  return (
    <div className="overflow-x-auto">
      <table className="w-full border-collapse">
        <thead>
          <tr className="bg-primary-dark text-background-light">
            <th className="p-4 text-left">Recursos</th>
            {options.map(option => (
              <th key={option.id} className="p-4 text-center">
                {option.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {/* Preço */}
          <tr className="border-b border-primary-dark/10">
            <td className="p-4 font-semibold">Preço</td>
            {options.map(option => (
              <td key={`price-${option.id}`} className="p-4 text-center">
                {option.price !== null ? (
                  <>
                    <div className="font-bold">R$ {option.price.toLocaleString('pt-BR')}</div>
                    <div className="text-sm text-primary-dark/70">Instalação inicial</div>
                  </>
                ) : (
                  <span className="font-bold">Sob consulta</span>
                )}
                
                {option.maintenanceFee !== undefined && option.maintenanceFee !== null && (
                  <div className="mt-1">
                    <div className="font-bold">R$ {option.maintenanceFee.toLocaleString('pt-BR')}</div>
                    <div className="text-sm text-primary-dark/70">Manutenção mensal</div>
                  </div>
                )}
              </td>
            ))}
          </tr>

          {/* Atendimento Automático */}
          <tr className="border-b border-primary-dark/10 bg-background-light/50">
            <td className="p-4 font-semibold">Atendimento Automático</td>
            {options.map(option => (
              <td key={`auto-${option.id}`} className="p-4 text-center">
                {option.id >= 1 ? (
                  <svg className="h-6 w-6 text-accent mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                ) : (
                  <svg className="h-6 w-6 text-primary-dark/30 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                  </svg>
                )}
              </td>
            ))}
          </tr>

          {/* Respostas a Perguntas Frequentes */}
          <tr className="border-b border-primary-dark/10">
            <td className="p-4 font-semibold">Respostas a Perguntas Frequentes</td>
            {options.map(option => (
              <td key={`faq-${option.id}`} className="p-4 text-center">
                {option.id >= 1 ? (
                  <svg className="h-6 w-6 text-accent mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                ) : (
                  <svg className="h-6 w-6 text-primary-dark/30 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                  </svg>
                )}
              </td>
            ))}
          </tr>

          {/* Envio de Informações e Links */}
          <tr className="border-b border-primary-dark/10 bg-background-light/50">
            <td className="p-4 font-semibold">Envio de Informações e Links</td>
            {options.map(option => (
              <td key={`info-${option.id}`} className="p-4 text-center">
                {option.id >= 1 ? (
                  <svg className="h-6 w-6 text-accent mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                ) : (
                  <svg className="h-6 w-6 text-primary-dark/30 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                  </svg>
                )}
              </td>
            ))}
          </tr>

          {/* Agendamento Automático */}
          <tr className="border-b border-primary-dark/10">
            <td className="p-4 font-semibold">Agendamento Automático</td>
            {options.map(option => (
              <td key={`schedule-${option.id}`} className="p-4 text-center">
                {option.id >= 2 ? (
                  <svg className="h-6 w-6 text-accent mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                ) : (
                  <svg className="h-6 w-6 text-primary-dark/30 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                  </svg>
                )}
              </td>
            ))}
          </tr>

          {/* Lembretes Automáticos */}
          <tr className="border-b border-primary-dark/10 bg-background-light/50">
            <td className="p-4 font-semibold">Lembretes Automáticos</td>
            {options.map(option => (
              <td key={`reminder-${option.id}`} className="p-4 text-center">
                {option.id >= 2 ? (
                  <svg className="h-6 w-6 text-accent mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                ) : (
                  <svg className="h-6 w-6 text-primary-dark/30 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                  </svg>
                )}
              </td>
            ))}
          </tr>

          {/* Integração com Sistemas */}
          <tr className="border-b border-primary-dark/10">
            <td className="p-4 font-semibold">Integração com Sistemas</td>
            {options.map(option => (
              <td key={`integration-${option.id}`} className="p-4 text-center">
                {option.id >= 3 ? (
                  <svg className="h-6 w-6 text-accent mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                ) : (
                  <svg className="h-6 w-6 text-primary-dark/30 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                  </svg>
                )}
              </td>
            ))}
          </tr>

          {/* Suporte */}
          <tr className="border-b border-primary-dark/10 bg-background-light/50">
            <td className="p-4 font-semibold">Suporte</td>
            {options.map(option => (
              <td key={`support-${option.id}`} className="p-4 text-center">
                {option.id === 1 && "E-mail"}
                {option.id === 2 && "Prioritário"}
                {option.id === 3 && "Dedicado"}
              </td>
            ))}
          </tr>

          {/* Botões de Ação */}
          <tr>
            <td className="p-4"></td>
            {options.map(option => (
              <td key={`action-${option.id}`} className="p-4 text-center">
                <Link 
                  to={buildContactUrl(option.title, option.price)}
                  className={`inline-block py-2 px-4 rounded ${option.id === 2 ? 'bg-accent hover:bg-accent-light text-background-light' : 'bg-primary-dark hover:bg-primary text-background-light'}`}
                  onClick={() => handleClick(option.title, option.price !== null ? 'contract' : 'quote')}
                >
                  {option.price !== null ? 'Contratar Agora' : 'Solicitar Proposta'}
                </Link>
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default AutomationPricingTable; 