import { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { 
  trackPageView, 
  trackEvent, 
  trackScrollDepth 
} from '../utils/analytics';

/**
 * Hook para rastreamento de analytics
 */
export const useAnalytics = () => {
  const location = useLocation();
  const [hasTrackedScroll, setHasTrackedScroll] = useState<{[key: number]: boolean}>({
    25: false,
    50: false,
    75: false,
    100: false
  });
  const scrollTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  
  // Rastreia visualizações de página quando a rota muda
  useEffect(() => {
    // Certifique-se de que o documento está definido (estamos no navegador)
    if (typeof document !== 'undefined') {
      const pageTitle = document.title;
      trackPageView({
        page_title: pageTitle,
        page_location: window.location.href,
        page_path: location.pathname
      });
      
      // Resetar o rastreamento de rolagem quando a página muda
      setHasTrackedScroll({
        25: false,
        50: false,
        75: false,
        100: false
      });
    }
  }, [location]);
  
  // Configura rastreamento de profundidade de rolagem
  useEffect(() => {
    // Certifique-se de que window está definido (estamos no navegador)
    if (typeof window === 'undefined') return;
    
    const handleScroll = () => {
      // Limpar o timeout anterior para evitar múltiplos eventos
      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current);
      }
      
      // Definir um novo timeout para limitar a frequência de rastreamento
      scrollTimeoutRef.current = setTimeout(() => {
        try {
          const scrollTop = window.scrollY;
          const docHeight = document.documentElement.scrollHeight;
          const winHeight = window.innerHeight;
          const scrollPercent = (scrollTop / (docHeight - winHeight)) * 100;
          
          // Rastreia em intervalos de 25% apenas uma vez por sessão de página
          if (scrollPercent >= 25 && !hasTrackedScroll[25]) {
            trackScrollDepth(25, document.title);
            setHasTrackedScroll(prev => ({ ...prev, 25: true }));
          }
          if (scrollPercent >= 50 && !hasTrackedScroll[50]) {
            trackScrollDepth(50, document.title);
            setHasTrackedScroll(prev => ({ ...prev, 50: true }));
          }
          if (scrollPercent >= 75 && !hasTrackedScroll[75]) {
            trackScrollDepth(75, document.title);
            setHasTrackedScroll(prev => ({ ...prev, 75: true }));
          }
          if (scrollPercent >= 100 && !hasTrackedScroll[100]) {
            trackScrollDepth(100, document.title);
            setHasTrackedScroll(prev => ({ ...prev, 100: true }));
          }
        } catch (error) {
          console.error('Erro ao rastrear profundidade de rolagem:', error);
        }
      }, 200);
    };
    
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current);
      }
    };
  }, [hasTrackedScroll]);
  
  return {
    trackEvent,
  };
};

export default useAnalytics; 