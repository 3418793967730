import React from 'react';
import EbookCard from '../components/EbookCard';
import founderImage from '../assets/images/about_founder.jpg';
import { ebooks } from '../data/ebooksData';
import SEO from '../components/common/SEO';
import { trackEbookPurchase } from '../utils/analytics';

const Ebooks: React.FC = () => {
  // O único e-book disponível
  const ebook = ebooks[0];
  
  // Link direto para compra na Hotmart
  const hotmartLink = "https://pay.hotmart.com/F95450739L?_hi=eyJzaWQiOiI3YmFkZTk4M2YyMGY0MWJlOTNkN2MzMDkwNDA5YmU5OCJ9.1741479272802&bid=1741479276444";

  // Função para rastrear cliques de conversão
  const handlePurchaseClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    try {
      trackEbookPurchase(
        'ebook-agentes-llm',
        'Introdução aos Agentes de LLM: Uma abordagem Prática',
        29.90
      );
    } catch (error) {
      console.error('Erro ao rastrear compra:', error);
      // Continua com a navegação mesmo se o rastreamento falhar
    }
  };

  // Structured data otimizado para este e-book específico
  const ebookSchema = {
    "@context": "https://schema.org",
    "@type": "Book",
    "name": "Introdução aos Agentes de LLM: Uma abordagem Prática",
    "description": "Um guia prático em português para programadores e interessados em IA aprenderem a construir agentes com Large Language Models (LLMs), com exemplos de código e tutoriais passo a passo.",
    "author": {
      "@type": "Person",
      "name": "Aron Ifanger Maciel",
      "jobTitle": "Especialista em Ciência de Dados e IA Generativa"
    },
    "publisher": {
      "@type": "Organization",
      "name": "Nyctea"
    },
    "inLanguage": "pt-BR",
    "offers": {
      "@type": "Offer",
      "price": "29.90",
      "priceCurrency": "BRL",
      "availability": "https://schema.org/InStock",
      "url": hotmartLink
    },
    "genre": ["Inteligência Artificial", "Programação", "LLM", "ChatGPT", "Agentes de IA"],
    "keywords": "large language models, LLM, agentes de IA, ChatGPT, IA generativa, programação de IA, transformers, GPT, Groq, framework ReAct",
    "accessMode": "textual",
    "accessibilityFeature": "readingOrder"
  };

  return (
    <>
      <SEO 
        title="Introdução aos Agentes de LLM: Uma abordagem Prática | E-book"
        description="Aprenda a construir agentes com Large Language Models (LLMs) neste guia prático em português. Ideal para programadores que desejam implementar IA generativa com exemplos de código e tutoriais passo a passo."
        canonical="/ebooks/agentes-llm"
        ogImage={ebook.coverImage}
        ogType="article"
        structuredData={ebookSchema}
        googleAdsId="AW-16917219212"
      />
      
      {/* Hero Section */}
      <section className="bg-gradient-to-r from-primary to-primary-dark text-white py-16">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl">
            <span className="bg-accent text-white text-sm px-3 py-1 rounded-full font-medium mb-4 inline-block">E-book em Português</span>
            <h1 className="text-4xl md:text-5xl font-bold mb-4 text-white">Introdução aos Agentes de LLM: Uma abordagem Prática</h1>
            <p className="text-xl text-blue-100 mb-6">
              O primeiro guia prático em português para construir agentes de IA utilizando Large Language Models como GPT e Groq (plataforma de inferência rápida).
            </p>
            <div className="flex flex-wrap gap-3 mb-6">
              <span className="bg-white/20 text-white px-3 py-1 rounded-full text-sm">Programação</span>
              <span className="bg-white/20 text-white px-3 py-1 rounded-full text-sm">Inteligência Artificial</span>
              <span className="bg-white/20 text-white px-3 py-1 rounded-full text-sm">LLM</span>
              <span className="bg-white/20 text-white px-3 py-1 rounded-full text-sm">ChatGPT</span>
              <span className="bg-white/20 text-white px-3 py-1 rounded-full text-sm">Tutoriais</span>
            </div>
            <a 
              href={hotmartLink} 
              target="_blank" 
              rel="noopener noreferrer"
              className="bg-accent hover:bg-accent-light text-white text-lg px-8 py-3 rounded-md font-bold inline-flex items-center transition-colors duration-300"
              onClick={handlePurchaseClick}
            >
              Comprar por R$ 29,90
              <svg className="w-5 h-5 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 5l7 7-7 7M5 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
        </div>
      </section>

      {/* Featured E-book Section with Author */}
      <section className="py-16 bg-gray-50">
        <div className="container mx-auto px-4">
          <div className="max-w-4xl mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center mb-12">
              <div className="flex justify-center">
                <img 
                  src={ebook.coverImage} 
                  alt="Capa do e-book Introdução aos Agentes de LLM" 
                  className="max-h-96 object-contain shadow-lg rounded-lg"
                />
              </div>
              <div>
                <h2 className="text-3xl font-bold mb-4 text-gray-900">Crie aplicações inteligentes com o poder da IA Generativa</h2>
                <p className="text-gray-700 mb-4 text-lg">
                  Com este e-book você será capaz de construir funcionalidades surpreendentes e incorporar o que há de melhor da inteligência artificial em seus projetos. Transforme a maneira como os usuários interagem com seus produtos.
                </p>
                <div className="mb-6">
                  <p className="text-gray-600 italic mb-3">
                    "Este ebook foi criado para ajudar programadores e outros interessados em Inteligência Artificial a mergulhar no fascinante mundo dos Large Language Models (LLMs) e na construção de agentes que utilizam essa poderosa tecnologia."
                  </p>
                  <p className="text-gray-600">
                    — Trecho da Apresentação
                  </p>
                </div>
                <div className="flex items-center justify-between mb-6">
                  <span className="text-gray-500 text-sm">
                    Publicado em: {new Date(ebook.releaseDate).toLocaleDateString('pt-BR')}
                  </span>
                  <span className="bg-green-500 text-white px-3 py-1 rounded-full text-sm font-medium">
                    Oferta por tempo limitado
                  </span>
                </div>
                <div className="bg-white p-4 rounded-lg shadow-sm mb-4 flex items-center justify-between">
                  <div>
                    <p className="text-gray-500 text-sm">Preço:</p>
                    <p className="text-2xl font-bold text-primary">R$ 29,90</p>
                  </div>
                  <a 
                    href={hotmartLink} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="bg-primary hover:bg-primary-dark text-white py-3 px-6 rounded-md font-medium transition-colors duration-300"
                    onClick={handlePurchaseClick}
                  >
                    Comprar Agora
                  </a>
                </div>
                <div className="flex items-center text-sm text-gray-600">
                  <svg className="w-5 h-5 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"></path>
                  </svg>
                  Garantia de 7 dias ou seu dinheiro de volta
                </div>
              </div>
            </div>
            
            {/* Sobre o Autor */}
            <div className="bg-white p-8 rounded-lg shadow-md mb-12">
              <h3 className="text-2xl font-bold mb-6 text-primary-dark">Sobre o Autor</h3>
              <div className="flex flex-col md:flex-row gap-6 items-center md:items-start">
                <div className="w-32 h-32 rounded-full overflow-hidden flex-shrink-0">
                  <img 
                    src={founderImage}
                    alt="Aron Ifanger Maciel"
                    className="w-full h-full object-cover"
                  />
                </div>
                <div>
                  <h4 className="text-xl font-bold mb-2">Aron Ifanger Maciel</h4>
                  <p className="text-gray-700 mb-4">
                    Criador do "Introdução aos Agentes de LLM: Uma Abordagem Prática", possui 10 anos de experiência com Inteligência Artificial, incluindo 6 anos na área de Processamento de Linguagem Natural (NLP). Graduado em Matemática Computacional e com mestrado em Sistemas Inteligentes pela Unifesp, Aron tem aplicado IA em diversos setores, como saúde, finanças e indústria de bebidas.
                  </p>
                  <p className="text-gray-700">
                    Com uma sólida trajetória na automação e otimização de processos através de IA, ele agora compartilha seu conhecimento prático neste livro, capacitando programadores a desenvolver soluções inovadoras com LLMs.
                  </p>
                </div>
              </div>
            </div>
            
            {/* O que você vai aprender */}
            <div className="bg-white p-8 rounded-lg shadow-md mb-12">
              <h3 className="text-2xl font-bold mb-6 text-primary-dark">O que você vai aprender neste e-book:</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="flex items-start">
                  <svg className="h-6 w-6 text-primary mt-0.5 mr-3 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <p>O que são Large Language Models (LLMs) e sua evolução histórica</p>
                </div>
                <div className="flex items-start">
                  <svg className="h-6 w-6 text-primary mt-0.5 mr-3 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <p>Principais arquiteturas: Transformer, GPT e BERT</p>
                </div>
                <div className="flex items-start">
                  <svg className="h-6 w-6 text-primary mt-0.5 mr-3 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <p>Conceito de Agentes de IA e como eles se aplicam aos LLMs</p>
                </div>
                <div className="flex items-start">
                  <svg className="h-6 w-6 text-primary mt-0.5 mr-3 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <p>Implementação prática do Framework ReAct com exemplos de código</p>
                </div>
                <div className="flex items-start">
                  <svg className="h-6 w-6 text-primary mt-0.5 mr-3 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <p>Como executar tarefas complexas com agentes de LLM</p>
                </div>
                <div className="flex items-start">
                  <svg className="h-6 w-6 text-primary mt-0.5 mr-3 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <p>Tutoriais passo a passo para construir seu primeiro agente</p>
                </div>
              </div>
            </div>
            
            {/* Sobre o e-book */}
            <div className="bg-white p-8 rounded-lg shadow-md mb-12">
              <h3 className="text-2xl font-bold mb-6 text-primary-dark">Sobre o e-book</h3>
              <p className="text-gray-700 mb-6">
                Com o e-book "Introdução aos Agentes de LLM", domine a automação inteligente e crie aplicações poderosas e automatizadas. Aprenda a desenvolver agentes de LLM que realizam tarefas repetitivas de forma precisa e eficiente, mesmo sem experiência prévia em IA. Aplique imediatamente o que aprendeu em cenários reais, aumentando sua produtividade e habilidades. Adquira o e-book agora e transforme a interação com IA em soluções eficazes.
              </p>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div className="text-center p-4 bg-gray-50 rounded-lg">
                  <svg className="w-10 h-10 mx-auto mb-3 text-primary" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                  </svg>
                  <h4 className="font-bold mb-2">Garantia de 7 dias</h4>
                  <p className="text-sm text-gray-600">Seu dinheiro de volta, sem necessidade de justificativas, em até 7 dias após a compra.</p>
                </div>
                <div className="text-center p-4 bg-gray-50 rounded-lg">
                  <svg className="w-10 h-10 mx-auto mb-3 text-primary" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path>
                  </svg>
                  <h4 className="font-bold mb-2">Formato PDF</h4>
                  <p className="text-sm text-gray-600">Leia seu e-book em qualquer dispositivo, a qualquer momento.</p>
                </div>
                <div className="text-center p-4 bg-gray-50 rounded-lg">
                  <svg className="w-10 h-10 mx-auto mb-3 text-primary" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"></path>
                  </svg>
                  <h4 className="font-bold mb-2">Acesso imediato</h4>
                  <p className="text-sm text-gray-600">Após a compra, receba seu e-book instantaneamente no e-mail cadastrado.</p>
                </div>
              </div>
            </div>
            
            {/* FAQ */}
            <div className="bg-white p-8 rounded-lg shadow-md">
              <h3 className="text-2xl font-bold mb-6 text-primary-dark">Perguntas Frequentes</h3>
              <div className="space-y-6">
                <div>
                  <h4 className="font-bold text-lg mb-2">Para quem este produto foi desenvolvido?</h4>
                  <p className="text-gray-700">
                    Este produto foi desenvolvido para programadores, entusiastas de Inteligência Artificial e profissionais de tecnologia que desejam aprender a criar aplicações poderosas utilizando Large Language Models (LLMs), como chatbots, secretárias eletrônicas e agentes de análise de dados. Tanto iniciantes quanto programadores mais experientes podem se beneficiar do conteúdo prático e acessível deste ebook.
                  </p>
                </div>
                <div>
                  <h4 className="font-bold text-lg mb-2">Como funciona o 'Período de Garantia'?</h4>
                  <p className="text-gray-700">
                    O Período de Garantia é o período de tempo que você tem para solicitar o reembolso total do valor pago pela sua compra, caso o produto não seja satisfatório para você. Para este e-book, oferecemos 7 dias de garantia incondicional.
                  </p>
                </div>
                <div>
                  <h4 className="font-bold text-lg mb-2">Quais modelos de LLM são abordados no e-book?</h4>
                  <p className="text-gray-700">
                    Abordamos os principais modelos disponíveis, incluindo GPT e BERT, com foco especial nas implementações práticas utilizando plataformas modernas como Groq para inferência rápida.
                  </p>
                </div>
                <div>
                  <h4 className="font-bold text-lg mb-2">O conteúdo inclui exemplos de código funcionais?</h4>
                  <p className="text-gray-700">
                    Sim! Cada capítulo contém exemplos práticos e tutoriais passo a passo com código funcional que você pode implementar imediatamente.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-16 bg-primary-dark text-white">
        <div className="container mx-auto px-4 text-center">
          <div className="max-w-3xl mx-auto">
            <h2 className="text-3xl font-bold mb-4">Oferta por tempo limitado</h2>
            <p className="text-xl mb-8">
              Comece sua jornada nos Agentes de LLM hoje. Adquira agora por apenas:
            </p>
            <div className="bg-white/10 p-8 rounded-lg mb-8">
              <div className="text-center">
                <p className="text-lg opacity-75 mb-2">APENAS</p>
                <p className="text-5xl font-bold mb-4">R$ 29,90</p>
                <p className="text-sm mb-6 opacity-75">Pagamento 100% seguro com aprovação instantânea</p>
                <a
                  href={hotmartLink}
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="px-8 py-4 bg-accent text-white rounded-md font-bold text-lg hover:bg-accent-light transition-colors inline-block w-full md:w-auto"
                  onClick={handlePurchaseClick}
                >
                  COMPRAR AGORA
                </a>
              </div>
            </div>
            <div className="flex items-center justify-center text-sm">
              <svg className="w-5 h-5 mr-2 text-accent" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path>
              </svg>
              Pagamento seguro processado pela Hotmart
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Ebooks; 