import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { BlogPost as BlogPostType } from '../types/blog';
import BlogTemplate from '../components/blog/BlogTemplate';
import useBlogPosts from '../hooks/useBlogPosts';
import SEO from '../components/common/SEO';
import { trackBlogEngagement } from '../utils/analytics';
import useAnalytics from '../hooks/useAnalytics';

const BlogPost: React.FC = () => {
  const { trackEvent } = useAnalytics();
  const { slug } = useParams<{ slug: string }>();
  const navigate = useNavigate();
  const { post, loading, error, getPostBySlug } = useBlogPosts();

  useEffect(() => {
    const fetchPost = async () => {
      if (slug) {
        const foundPost = await getPostBySlug(slug);
        
        if (!foundPost) {
          // Redirecionar para a página de blog se o post não for encontrado
          navigate('/blog', { replace: true });
        }
      }
    };
    
    fetchPost();
  }, [slug, navigate, getPostBySlug]);

  // Rastreia quando o usuário lê o artigo por mais de 30 segundos
  useEffect(() => {
    if (post && post.title) {
      const timer = setTimeout(() => {
        trackBlogEngagement(slug || '', post.title, 'read');
      }, 30000);
      
      return () => clearTimeout(timer);
    }
  }, [slug, post]);
  
  // Rastreia compartilhamentos
  const handleShare = (platform: string) => {
    if (post && post.title) {
      trackBlogEngagement(slug || '', post.title, `share_${platform}`);
      // ... código de compartilhamento ...
    }
  };

  if (loading) {
    return (
      <div className="container mx-auto px-4 py-12">
        <div className="max-w-4xl mx-auto text-center">
          <div className="animate-pulse">
            <div className="h-8 bg-gray-200 rounded w-3/4 mx-auto mb-4"></div>
            <div className="h-4 bg-gray-200 rounded w-1/2 mx-auto mb-8"></div>
            <div className="h-64 bg-gray-200 rounded mb-6"></div>
            <div className="h-4 bg-gray-200 rounded mb-2"></div>
            <div className="h-4 bg-gray-200 rounded mb-2"></div>
            <div className="h-4 bg-gray-200 rounded mb-2"></div>
            <div className="h-4 bg-gray-200 rounded mb-2"></div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mx-auto px-4 py-12">
        <div className="max-w-4xl mx-auto text-center">
          <h1 className="text-4xl font-bold text-primary-dark mb-2">Oops!</h1>
          <p className="text-lg text-gray-600 mb-8">
            Ocorreu um erro ao carregar o post. Por favor, tente novamente mais tarde.
          </p>
          <button 
            className="px-4 py-2 bg-primary text-white rounded-lg hover:bg-accent transition-colors"
            onClick={() => window.location.reload()}
          >
            Tentar novamente
          </button>
        </div>
      </div>
    );
  }

  if (!post) {
    return null; // Isso não deve acontecer devido ao redirecionamento, mas é bom ter
  }

  // Create structured data for the blog post
  const articleSchema = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "headline": post.title,
    "description": post.excerpt,
    "image": post.coverImage || "https://nyctea.com.br/og-image.jpg",
    "datePublished": post.date,
    "dateModified": post.date,
    "author": {
      "@type": "Person",
      "name": post.author || "Nyctea Team"
    },
    "publisher": {
      "@type": "Organization",
      "name": "Nyctea",
      "logo": {
        "@type": "ImageObject",
        "url": "https://nyctea.com.br/logo192.png"
      }
    },
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": `https://nyctea.com.br/blog/${post.slug}`
    }
  };

  return (
    <>
      <SEO 
        title={post.title}
        description={post.excerpt}
        canonical={`/blog/${post.slug}`}
        ogImage={post.coverImage || "/og-image.jpg"}
        ogType="article"
        structuredData={articleSchema}
      />
      <BlogTemplate post={post} />
    </>
  );
};

export default BlogPost; 