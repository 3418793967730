import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { BlogPost } from '../types/blog';
import ImageWithFallback from '../components/common/ImageWithFallback';
import defaultFallbackImage from '../assets/images/blog/default-fallback.jpg';
import useBlogPosts from '../hooks/useBlogPosts';
import SEO from '../components/common/SEO';

const Blog: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTag, setSelectedTag] = useState<string | null>(null);
  
  const { 
    loading, 
    error, 
    filteredPosts, 
    allTags,
    filterPostsByTag,
    filterPostsBySearch
  } = useBlogPosts();
  
  // Structured data for blog page
  const blogSchema = {
    "@context": "https://schema.org",
    "@type": "Blog",
    "name": "Blog Nyctea",
    "description": "Novidades, dicas e insights sobre automação, marketing digital e tecnologia",
    "url": "https://nyctea.com.br/blog",
    "blogPost": filteredPosts.slice(0, 10).map(post => ({
      "@type": "BlogPosting",
      "headline": post.title,
      "description": post.excerpt,
      "url": `https://nyctea.com.br/blog/${post.slug}`,
      "datePublished": post.date
    }))
  };
  
  // Aplicar filtros quando o termo de pesquisa ou tag selecionada mudar
  useEffect(() => {
    if (selectedTag) {
      filterPostsByTag(selectedTag);
    } else if (searchTerm) {
      filterPostsBySearch(searchTerm);
    } else {
      // Se não houver filtros, o hook já retorna todos os posts em filteredPosts
    }
  }, [selectedTag, searchTerm, filterPostsByTag, filterPostsBySearch]);

  // Atualizar a pesquisa
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value;
    setSearchTerm(term);
    setSelectedTag(null); // Limpar a tag selecionada ao pesquisar
  };

  // Atualizar a tag selecionada
  const handleTagSelect = (tag: string | null) => {
    setSelectedTag(tag);
    setSearchTerm(''); // Limpar a pesquisa ao selecionar uma tag
  };

  if (loading) {
    return (
      <div className="container mx-auto px-4 py-12">
        <div className="max-w-6xl mx-auto">
          <h1 className="text-4xl font-bold text-primary-dark mb-2">Blog</h1>
          <p className="text-lg text-gray-600 mb-8">
            Novidades, dicas e insights sobre automação, marketing digital e tecnologia
          </p>
          
          <div className="animate-pulse">
            <div className="h-10 bg-gray-200 rounded w-full mb-8"></div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {[1, 2, 3, 4, 5, 6].map((item) => (
                <div key={item} className="bg-background-light rounded-lg shadow-md overflow-hidden">
                  <div className="h-48 bg-gray-200"></div>
                  <div className="p-6">
                    <div className="h-4 bg-gray-200 rounded w-1/4 mb-2"></div>
                    <div className="h-6 bg-gray-200 rounded w-3/4 mb-2"></div>
                    <div className="h-4 bg-gray-200 rounded w-full mb-2"></div>
                    <div className="h-4 bg-gray-200 rounded w-full mb-2"></div>
                    <div className="h-4 bg-gray-200 rounded w-1/2 mb-2"></div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mx-auto px-4 py-12">
        <div className="max-w-6xl mx-auto text-center">
          <h1 className="text-4xl font-bold text-primary-dark mb-2">Oops!</h1>
          <p className="text-lg text-gray-600 mb-8">
            Ocorreu um erro ao carregar os posts do blog. Por favor, tente novamente mais tarde.
          </p>
          <button 
            className="px-4 py-2 bg-primary text-white rounded-lg hover:bg-accent transition-colors"
            onClick={() => window.location.reload()}
          >
            Tentar novamente
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-12">
      <SEO 
        title="Blog"
        description="Fique por dentro das novidades, dicas e insights sobre automação, marketing digital e tecnologia no Blog da Nyctea."
        canonical="/blog"
        structuredData={blogSchema}
      />
      
      <div className="max-w-6xl mx-auto">
        <h1 className="text-4xl font-bold text-primary-dark mb-2">Blog</h1>
        <p className="text-lg text-gray-600 mb-8">
          Novidades, dicas e insights sobre automação, marketing digital e tecnologia
        </p>
        
        {/* Barra de pesquisa e filtros */}
        <div className="flex flex-col md:flex-row gap-4 mb-8">
          <div className="flex-grow">
            <div className="relative">
              <input
                type="text"
                placeholder="Pesquisar artigos..."
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary"
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <svg 
                className="absolute right-3 top-2.5 h-5 w-5 text-gray-400" 
                fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24" 
                xmlns="http://www.w3.org/2000/svg"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth="2" 
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </div>
          </div>
          
          <div className="flex flex-wrap gap-2">
            <button
              className={`px-3 py-1 rounded-full text-sm ${
                selectedTag === null 
                  ? 'bg-primary text-white' 
                  : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
              }`}
              onClick={() => handleTagSelect(null)}
            >
              Todos
            </button>
            
            {allTags.map((tag, index) => (
              <button
                key={`${tag}-${index}`}
                className={`px-3 py-1 rounded-full text-sm ${
                  selectedTag === tag 
                    ? 'bg-primary text-white' 
                    : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                }`}
                onClick={() => handleTagSelect(tag)}
              >
                {tag}
              </button>
            ))}
          </div>
        </div>
        
        {/* Lista de posts */}
        {filteredPosts.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {filteredPosts.map((post: BlogPost) => (
              <BlogPostCard key={post.id} post={post} />
            ))}
          </div>
        ) : (
          <div className="text-center py-12">
            <p className="text-xl text-gray-600">Nenhum artigo encontrado para sua pesquisa.</p>
            <button 
              className="mt-4 text-primary hover:text-accent"
              onClick={() => {
                setSearchTerm('');
                setSelectedTag(null);
              }}
            >
              Limpar filtros
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

interface BlogPostCardProps {
  post: BlogPost;
}

const BlogPostCard: React.FC<BlogPostCardProps> = ({ post }) => {
  return (
    <div className="bg-background-light rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300">
      {post.coverImage && (
        <div className="h-48 overflow-hidden">
          <ImageWithFallback 
            src={post.coverImage} 
            alt={post.title} 
            fallbackSrc={defaultFallbackImage}
            className="w-full h-full object-cover transition-transform duration-300 hover:scale-105"
          />
        </div>
      )}
      
      <div className="p-6">
        <div className="flex items-center text-xs text-gray-500 mb-2">
          <span>{new Date(post.date).toLocaleDateString('pt-BR')}</span>
          {post.tags && post.tags.length > 0 && (
            <>
              <span className="mx-2">•</span>
              <span>{post.tags[0]}</span>
            </>
          )}
        </div>
        
        <h2 className="text-xl font-bold text-primary-dark mb-2 line-clamp-2">
          {post.title}
        </h2>
        
        <p className="text-gray-600 mb-4 line-clamp-3">
          {post.excerpt}
        </p>
        
        <Link 
          to={`/blog/${post.slug}`} 
          className="text-primary font-medium hover:text-accent inline-flex items-center"
        >
          Ler mais
          <svg 
            className="w-4 h-4 ml-1" 
            fill="none" 
            stroke="currentColor" 
            viewBox="0 0 24 24" 
            xmlns="http://www.w3.org/2000/svg"
          >
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth="2" 
              d="M14 5l7 7m0 0l-7 7m7-7H3"
            />
          </svg>
        </Link>
      </div>
    </div>
  );
};

export default Blog; 