import React from 'react';
import { Link } from 'react-router-dom';
import SEO from '../components/common/SEO';
import heroImage from '../assets/images/home_hero.png';
import whyImage from '../assets/images/home_why.png';
import automationImage from '../assets/images/home_services_automation.png';
import socialMediaImage from '../assets/images/home_services_social_media.png';
import indoorMediaImage from '../assets/images/home_services_indoor.png';
import initialAnalysisImage from '../assets/images/home_process_initial_analisys.png';
import proposalImage from '../assets/images/home_process_proposal.png';
import implementationImage from '../assets/images/home_process_implementation.png';

const Home: React.FC = () => {
  // Structured data for organization
  const organizationSchema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Nyctea",
    "url": "https://nyctea.com.br",
    "logo": "https://nyctea.com.br/logo192.png",
    "description": "Inteligência Artificial aplicada ao seu negócio. Impulsione sua presença digital com inteligência artificial e estratégias personalizadas.",
    "sameAs": [
      "https://www.facebook.com/nyctea",
      "https://www.instagram.com/nyctea",
      "https://www.linkedin.com/company/nyctea"
    ]
  };

  return (
    <div>
      <SEO 
        title="Nyctea Platform" 
        description="Inteligência Artificial aplicada ao seu negócio. Impulsione sua presença digital com inteligência artificial e estratégias personalizadas."
        canonical="/"
        structuredData={organizationSchema}
      />
      
      {/* Hero Section */}
      <section 
        className="py-20 bg-cover bg-center relative" 
        style={{ backgroundImage: `url(${heroImage})` }}
      >
        {/* Overlay para garantir legibilidade do texto */}
        <div className="absolute inset-0 bg-primary bg-opacity-70"></div>
        
        <div className="container mx-auto px-4 relative z-10">
          <div className="max-w-3xl mx-auto text-center">
            <h1 className="text-4xl md:text-5xl font-bold text-background-light mb-6 text-shadow-md">
              Inteligência Artificial aplicada ao seu negócio
            </h1>
            <p className="text-xl text-background mb-8 text-shadow-sm">
              Impulsione sua presença digital com inteligência artificial e estratégias personalizadas
            </p>
            <Link to="/services" className="btn-accent text-lg px-8 py-3 shadow-lg">
              Descubra Nossas Soluções
            </Link>
          </div>
        </div>
      </section>

      {/* Serviços em Destaque */}
      <section className="py-16 bg-background">
        <div className="container mx-auto px-4">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold mb-4">Nossos Serviços</h2>
            <p className="text-lg text-text max-w-2xl mx-auto">
              Oferecemos soluções inovadoras para ajudar seu negócio a crescer.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {/* Serviço 1 */}
            <div className="card text-center overflow-hidden">
              <div className="mb-4 h-48 overflow-hidden">
                <img 
                  src={automationImage} 
                  alt="Automação Inteligente" 
                  className="w-full h-full object-cover transition-transform duration-300 hover:scale-105"
                />
              </div>
              <h3 className="text-xl font-semibold mb-2">Automação Inteligente</h3>
              <p className="text-text mb-4">
                Utilize chatbots e automação para otimizar o atendimento e melhorar a experiência do cliente.
              </p>
              <Link to="/services/automation" className="text-accent hover:text-accent-light transition-colors">
                Saiba Mais →
              </Link>
            </div>

            {/* Serviço 2 */}
            <div className="card text-center overflow-hidden">
              <div className="mb-4 h-48 overflow-hidden">
                <img 
                  src={socialMediaImage} 
                  alt="Gestão de Redes Sociais" 
                  className="w-full h-full object-cover transition-transform duration-300 hover:scale-105"
                />
              </div>
              <h3 className="text-xl font-semibold mb-2">Gestão de Redes Sociais</h3>
              <p className="text-text mb-4">
                Criação e gerenciamento de conteúdo para aumentar o engajamento e a visibilidade da sua marca.
              </p>
              <Link to="/services/social-media" className="text-accent hover:text-accent-light transition-colors">
                Saiba Mais →
              </Link>
            </div>

            {/* Serviço 3 - Presença Digital Otimizada (COMENTADO)
            <div className="card text-center overflow-hidden">
              <div className="mb-4 h-48 overflow-hidden">
                <img 
                  src={presenceImage} 
                  alt="Presença Digital Otimizada" 
                  className="w-full h-full object-cover transition-transform duration-300 hover:scale-105"
                />
              </div>
              <h3 className="text-xl font-semibold mb-2">Presença Digital Otimizada</h3>
              <p className="text-text mb-4">
                Melhore sua visibilidade online com estratégias de SEO e presença local.
              </p>
              <Link to="/services/digital-presence" className="text-accent hover:text-accent-light transition-colors">
                Saiba Mais →
              </Link>
            </div> */}

            {/* Serviço 4 */}
            <div className="card text-center overflow-hidden">
              <div className="mb-4 h-48 overflow-hidden">
                <img 
                  src={indoorMediaImage} 
                  alt="Mídia Indoor" 
                  className="w-full h-full object-cover transition-transform duration-300 hover:scale-105"
                />
              </div>
              <h3 className="text-xl font-semibold mb-2">Mídia Indoor</h3>
              <p className="text-text mb-4">
                Anuncie em locais estratégicos para alcançar novos públicos.
              </p>
              <Link to="/services/indoor-media" className="text-accent hover:text-accent-light transition-colors">
                Saiba Mais →
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* Por que escolher a Nyctea? */}
      <section 
        className="py-16 bg-cover bg-center relative" 
        style={{ backgroundImage: `url(${whyImage})` }}
      >
        {/* Overlay cinza para suavizar a imagem de fundo */}
        <div className="absolute inset-0 bg-slate-800 bg-opacity-75"></div>
        
        <div className="container mx-auto px-4 relative z-10">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold mb-4 text-background-light text-shadow-title">Por que escolher a Nyctea?</h2>
            <p className="text-lg text-background max-w-2xl mx-auto text-shadow-md">
              Nosso compromisso é oferecer soluções inovadoras e eficientes para o seu negócio.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {/* Diferencial 1 */}
            <div className="p-6 text-center bg-primary-dark bg-opacity-70 rounded-lg backdrop-filter backdrop-blur-md border border-accent/20">
              <div className="bg-accent rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-4">
                <svg className="w-8 h-8 text-primary-dark" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 3v2m6-2v2M9 19v2m6-2v2M5 9H3m2 6H3m18-6h-2m2 6h-2M7 19h10a2 2 0 002-2V7a2 2 0 00-2-2H7a2 2 0 00-2 2v10a2 2 0 002 2zM9 9h6v6H9V9z" />
                </svg>
              </div>
              <h3 className="text-xl font-semibold mb-2 text-background-light">Expertise em AI</h3>
              <p className="text-background">
                Aplique inteligência artificial para transformar a maneira como você interage com seus clientes.
              </p>
            </div>

            {/* Diferencial 2 */}
            <div className="p-6 text-center bg-primary-dark bg-opacity-70 rounded-lg backdrop-filter backdrop-blur-md border border-accent/20">
              <div className="bg-accent rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-4">
                <svg className="w-8 h-8 text-primary-dark" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                </svg>
              </div>
              <h3 className="text-xl font-semibold mb-2 text-background-light">Soluções Personalizadas</h3>
              <p className="text-background">
                Cada estratégia é adaptada às necessidades específicas do seu negócio.
              </p>
            </div>

            {/* Diferencial 3 */}
            <div className="p-6 text-center bg-primary-dark bg-opacity-70 rounded-lg backdrop-filter backdrop-blur-md border border-accent/20">
              <div className="bg-accent rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-4">
                <svg className="w-8 h-8 text-primary-dark" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                </svg>
              </div>
              <h3 className="text-xl font-semibold mb-2 text-background-light">Foco em Resultados</h3>
              <p className="text-background">
                Comprometido com a entrega de resultados tangíveis e mensuráveis.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Como Funciona */}
      <section className="py-16 bg-background">
        <div className="container mx-auto px-4">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold mb-4">Como Funciona</h2>
            <p className="text-lg text-text max-w-2xl mx-auto">
              Um processo simples e eficiente para transformar seu negócio.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {/* Etapa 1 */}
            <div className="card text-center p-6 overflow-hidden">
              <div className="h-48 mb-6 overflow-hidden">
                <img 
                  src={initialAnalysisImage} 
                  alt="Análise Inicial" 
                  className="w-full h-full object-contain transition-transform duration-300 hover:scale-105"
                />
              </div>
              <h3 className="text-xl font-semibold mb-2">Análise Inicial</h3>
              <p className="text-text">
                Entendemos suas necessidades e objetivos através de uma análise detalhada do seu negócio.
              </p>
            </div>

            {/* Etapa 2 */}
            <div className="card text-center p-6 overflow-hidden">
              <div className="h-48 mb-6 overflow-hidden">
                <img 
                  src={proposalImage} 
                  alt="Proposta Personalizada" 
                  className="w-full h-full object-contain transition-transform duration-300 hover:scale-105"
                />
              </div>
              <h3 className="text-xl font-semibold mb-2">Proposta Personalizada</h3>
              <p className="text-text">
                Desenvolvemos uma estratégia sob medida para atender às necessidades específicas do seu negócio.
              </p>
            </div>

            {/* Etapa 3 */}
            <div className="card text-center p-6 overflow-hidden">
              <div className="h-48 mb-6 overflow-hidden">
                <img 
                  src={implementationImage} 
                  alt="Implementação e Acompanhamento" 
                  className="w-full h-full object-contain transition-transform duration-300 hover:scale-105"
                />
              </div>
              <h3 className="text-xl font-semibold mb-2">Implementação e Acompanhamento</h3>
              <p className="text-text">
                Executamos a estratégia e monitoramos constantemente os resultados para garantir o sucesso do projeto.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Nossa Missão */}
      <section className="py-16 bg-background-light">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-3xl font-bold mb-6">Nossa Missão</h2>
            <p className="text-lg text-text mb-6">
              Transformar negócios através do poder da inteligência artificial, tornando tecnologias avançadas acessíveis e aplicáveis para empresas de todos os portes. Buscamos criar soluções que não apenas automatizam processos, mas que verdadeiramente potencializam o crescimento e a inovação.
            </p>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-16 bg-primary-dark">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold text-background-light mb-6">
            Pronto para transformar seu negócio?
          </h2>
          <p className="text-xl text-background mb-8 max-w-2xl mx-auto">
            Entre em contato e descubra como posso ajudar você a conquistar mais clientes e fortalecer sua presença digital.
          </p>
          <Link to="/contact" className="btn-accent text-lg px-8 py-3">
            Entre em Contato Agora
          </Link>
        </div>
      </section>
    </div>
  );
};

export default Home; 