import React from 'react';
import { Link } from 'react-router-dom';
import { comboOption, services } from '../../data/servicesData';
import ComboCard from '../../components/ComboCard';

const Combo: React.FC = () => {
  // Filtrar apenas os serviços incluídos no combo
  const includedServiceSlugs = ['automation', 'social-media', 'indoor-media'];
  const includedServices = services.filter(service => includedServiceSlugs.includes(service.slug));

  return (
    <div>
      {/* Hero Section */}
      <section className="bg-primary-dark py-16">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto text-center">
            <Link to="/services" className="text-background hover:text-accent mb-4 inline-flex items-center">
              <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 19l-7-7m0 0l7-7m-7 7h18"></path>
              </svg>
              Voltar para Serviços
            </Link>
            <h1 className="text-4xl font-bold text-background-light mb-4">{comboOption.title}</h1>
            <p className="text-xl text-background mb-6">
              A solução completa para transformar seu negócio com nossos melhores serviços integrados
            </p>
          </div>
        </div>
      </section>

      {/* Combo Section */}
      <section className="py-16 bg-background">
        <div className="container mx-auto px-4">
          <div className="max-w-5xl mx-auto">
            <ComboCard combo={comboOption} />
          </div>
        </div>
      </section>

      {/* Services Overview */}
      <section className="py-16 bg-background-light">
        <div className="container mx-auto px-4">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-primary-dark mb-4">Serviços Incluídos</h2>
            <p className="text-xl text-primary max-w-2xl mx-auto">
              Conheça em detalhes cada um dos serviços que compõem nosso pacote completo
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {includedServices.map((service) => (
              <div key={service.id} className="bg-background rounded-lg shadow-md overflow-hidden h-full flex flex-col">
                <div className="p-6 flex flex-col h-full">
                  <h3 className="text-xl font-semibold text-primary-dark mb-2">{service.title}</h3>
                  <p className="text-primary mb-4 flex-grow">{service.description}</p>
                  <Link 
                    to={`/services/${service.slug}`} 
                    className="text-accent hover:text-accent-light transition-colors mt-auto inline-flex items-center"
                  >
                    Ver detalhes 
                    <svg className="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                    </svg>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Benefits Section */}
      <section className="py-16 bg-background">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto">
            <h2 className="text-3xl font-bold text-primary-dark mb-6 text-center">Por que escolher o Pacote Completo?</h2>
            <div className="space-y-6">
              <div className="flex items-start">
                <div className="bg-accent rounded-full p-2 mr-4 flex-shrink-0">
                  <svg className="w-6 h-6 text-background-light" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                  </svg>
                </div>
                <div>
                  <h3 className="text-xl font-semibold text-primary-dark mb-2">Economia Significativa</h3>
                  <p className="text-primary">Economize R$ 468 mensais em comparação com a contratação individual de cada serviço, além de 15% de desconto na instalação do chatbot.</p>
                </div>
              </div>
              <div className="flex items-start">
                <div className="bg-accent rounded-full p-2 mr-4 flex-shrink-0">
                  <svg className="w-6 h-6 text-background-light" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z"></path>
                  </svg>
                </div>
                <div>
                  <h3 className="text-xl font-semibold text-primary-dark mb-2">Estratégia Integrada</h3>
                  <p className="text-primary">Todos os serviços trabalham em sinergia, maximizando os resultados para seu negócio. Automação, redes sociais e mídia indoor se complementam para uma presença de marca completa.</p>
                </div>
              </div>
              <div className="flex items-start">
                <div className="bg-accent rounded-full p-2 mr-4 flex-shrink-0">
                  <svg className="w-6 h-6 text-background-light" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                  </svg>
                </div>
                <div>
                  <h3 className="text-xl font-semibold text-primary-dark mb-2">Gestão Simplificada</h3>
                  <p className="text-primary">Um único ponto de contato para todos os serviços, facilitando a comunicação e o acompanhamento. Seu gerente de conta exclusivo coordena todas as ações.</p>
                </div>
              </div>
              <div className="flex items-start">
                <div className="bg-accent rounded-full p-2 mr-4 flex-shrink-0">
                  <svg className="w-6 h-6 text-background-light" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"></path>
                  </svg>
                </div>
                <div>
                  <h3 className="text-xl font-semibold text-primary-dark mb-2">Prioridade no Atendimento</h3>
                  <p className="text-primary">Clientes do pacote completo recebem atendimento prioritário e acesso a recursos exclusivos, como consultoria estratégica trimestral.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="bg-primary-dark py-16">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold text-background-light mb-6">
            Pronto para revolucionar seu negócio?
          </h2>
          <p className="text-xl text-background mb-8 max-w-2xl mx-auto">
            Entre em contato hoje mesmo para discutir como nosso Pacote Completo pode transformar sua presença digital.
          </p>
          <Link to="/contact" className="btn-accent text-lg px-8 py-3">
            Fale Conosco
          </Link>
        </div>
      </section>
    </div>
  );
};

export default Combo; 