import React from 'react';

interface LocationInfoProps {
  title: string;
  address: string;
  mapImageSrc: string;
  locationImageSrc: string;
}

const LocationInfo: React.FC<LocationInfoProps> = ({ 
  title, 
  address, 
  mapImageSrc, 
  locationImageSrc 
}) => {
  return (
    <div className="bg-background-light rounded-lg shadow-md overflow-hidden">
      <div className="p-6">
        <h3 className="text-xl font-semibold text-primary-dark mb-4">{title}</h3>
        <p className="text-primary mb-6">{address}</p>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <h4 className="text-lg font-medium text-primary-dark mb-3">Localização no Mapa</h4>
            <div className="aspect-square overflow-hidden rounded-lg border border-gray-200">
              <img 
                src={mapImageSrc} 
                alt="Mapa de localização" 
                className="w-full h-full object-cover"
              />
            </div>
          </div>
          
          <div>
            <h4 className="text-lg font-medium text-primary-dark mb-3">Tela Expositora</h4>
            <div className="aspect-square overflow-hidden rounded-lg border border-gray-200">
              <img 
                src={locationImageSrc} 
                alt="Tela expositora" 
                className="w-full h-full object-cover"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationInfo; 