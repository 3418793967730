import React from 'react';
import { Link } from 'react-router-dom';
import SEO from '../components/common/SEO';
import automationImage from '../assets/images/home_services_automation.png';
import socialMediaImage from '../assets/images/home_services_social_media.png';
import presenceImage from '../assets/images/home_services_presence.png';
import indoorMediaImage from '../assets/images/home_services_indoor.png';
import { comboOption } from '../data/servicesData';

// Dados dos serviços alinhados com a página Home
const services = [
  {
    id: 1,
    title: 'Automação Inteligente',
    description: 'Utilize chatbots e automação para otimizar o atendimento ao cliente, reduzir tempo de resposta e aumentar a satisfação. Nossas soluções de IA personalizam interações e automatizam tarefas repetitivas, permitindo que sua equipe foque no que realmente importa.',
    image: automationImage,
    slug: 'automation'
  },
  {
    id: 2,
    title: 'Gestão de Redes Sociais',
    description: 'Criação e gerenciamento estratégico de conteúdo para aumentar o engajamento, visibilidade e conversão da sua marca. Desenvolvemos calendários editoriais, criamos posts otimizados e monitoramos métricas para garantir resultados consistentes em todas as plataformas.',
    image: socialMediaImage,
    slug: 'social-media'
  },
  /* Serviço de Presença Digital Otimizada temporariamente removido
  {
    id: 3,
    title: 'Presença Digital Otimizada',
    description: 'Melhore sua visibilidade online com estratégias avançadas de SEO, Google Meu Negócio e presença local. Otimizamos seu site para mecanismos de busca, criamos conteúdo relevante e garantimos que seu negócio seja encontrado pelos clientes certos no momento certo.',
    image: presenceImage,
    slug: 'digital-presence'
  },
  */
  {
    id: 4,
    title: 'Mídia Indoor',
    description: 'Transforme a experiência do cliente em seu ambiente físico com conteúdo digital personalizado. Nossas soluções de mídia indoor combinam tecnologia e criatividade para engajar seu público, transmitir sua mensagem e fortalecer sua marca no ponto de venda.',
    image: indoorMediaImage,
    slug: 'indoor-media'
  }
];

const Services: React.FC = () => {
  // Structured data for services page
  const servicesSchema = {
    "@context": "https://schema.org",
    "@type": "ItemList",
    "itemListElement": services.map((service, index) => ({
      "@type": "ListItem",
      "position": index + 1,
      "item": {
        "@type": "Service",
        "name": service.title,
        "description": service.description,
        "url": `https://nyctea.com.br/services/${service.slug}`,
        "provider": {
          "@type": "Organization",
          "name": "Nyctea"
        }
      }
    }))
  };

  return (
    <div>
      <SEO 
        title="Nossos Serviços"
        description="Descubra como a Nyctea pode transformar seu negócio com soluções de IA, automação, gestão de redes sociais e mídia indoor."
        canonical="/services"
        structuredData={servicesSchema}
      />
      
      {/* Page Header */}
      <section className="bg-primary-dark py-16">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto text-center">
            <h1 className="text-4xl font-bold text-background-light mb-4">Nossos Serviços</h1>
            <p className="text-xl text-background">
              Soluções completas adaptadas às necessidades do seu negócio
            </p>
          </div>
        </div>
      </section>

      {/* Services Grid */}
      <section className="py-16">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {services.map((service) => (
              <ServiceCard key={service.id} service={service} />
            ))}
          </div>
          
          {/* Combo Card */}
          <div className="mt-16">
            <h2 className="text-2xl font-bold text-primary-dark mb-6 text-center">Pacote Completo com Desconto Especial</h2>
            <div className="max-w-4xl mx-auto">
              <ComboCardPreview />
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="bg-primary-dark py-16">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold text-background-light mb-6">
            Pronto para começar?
          </h2>
          <p className="text-xl text-background mb-8 max-w-2xl mx-auto">
            Entre em contato hoje mesmo para discutir como nossos serviços podem ajudar seu negócio a crescer.
          </p>
          <Link to="/contact" className="btn-accent text-lg px-8 py-3">
            Fale Conosco
          </Link>
        </div>
      </section>
    </div>
  );
};

// Service Card Component
interface ServiceProps {
  service: {
    id: number;
    title: string;
    description: string;
    image: string;
    slug: string;
  };
}

const ServiceCard: React.FC<ServiceProps> = ({ service }) => {
  return (
    <div className="bg-background-light rounded-lg overflow-hidden shadow-lg transition-transform duration-300 hover:transform hover:scale-105 h-full flex flex-col">
      <div className="aspect-video overflow-hidden px-6 pt-6">
        <img 
          src={service.image} 
          alt={service.title} 
          className="w-full h-full object-contain rounded-lg"
        />
      </div>
      <div className="p-6 flex-grow flex flex-col">
        <h3 className="text-xl font-semibold text-primary-dark mb-2">{service.title}</h3>
        <p className="text-primary mb-4 flex-grow">{service.description}</p>
        <Link 
          to={`/services/${service.slug}`} 
          className="text-accent hover:text-accent-light transition-colors mt-auto inline-flex items-center"
        >
          Saiba mais 
          <svg className="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
          </svg>
        </Link>
      </div>
    </div>
  );
};

// Combo Card Preview Component
const ComboCardPreview: React.FC = () => {
  // Calcular a economia mensal
  const monthlySavings = comboOption.originalPrice - comboOption.price;
  // Calcular a economia na instalação
  const installationSavings = comboOption.originalInstallationPrice - comboOption.installationPrice;

  return (
    <div className="bg-gradient-to-br from-primary-dark to-primary rounded-lg shadow-xl overflow-hidden transition-transform duration-300 hover:transform hover:scale-105">
      <div className="bg-accent text-white py-2 px-4 text-center">
        <span className="text-lg font-bold">Economize R$ {monthlySavings.toLocaleString('pt-BR')} mensais + R$ {installationSavings.toLocaleString('pt-BR')} na instalação</span>
      </div>
      <div className="p-6 md:p-8 text-white">
        <div className="flex flex-col md:flex-row items-center md:items-start gap-8">
          <div className="md:w-2/3">
            <h3 className="text-2xl font-bold mb-3">{comboOption.title}</h3>
            <p className="text-gray-100 mb-6">{comboOption.description}</p>
            
            <div className="mb-6">
              <h4 className="text-xl font-semibold mb-3 text-white">Serviços Incluídos:</h4>
              <ul className="space-y-2">
                {comboOption.includedServices.map((service, index) => (
                  <li key={index} className="flex items-start">
                    <svg className="h-5 w-5 text-yellow-300 mt-0.5 mr-2 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                    <span className="text-gray-100">{service}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          
          <div className="md:w-1/3 bg-primary-dark/30 p-6 rounded-lg w-full md:w-auto">
            <div className="mb-4">
              <div className="flex flex-col items-center md:items-start">
                <div className="flex items-center mb-1">
                  <span className="text-3xl font-bold">R$ {comboOption.price.toLocaleString('pt-BR')}</span>
                  <span className="ml-2 text-gray-100">/mês</span>
                </div>
                <div className="text-gray-200 line-through text-center md:text-left mb-3">
                  R$ {comboOption.originalPrice.toLocaleString('pt-BR')}/mês
                </div>
                
                <div className="bg-yellow-500/20 p-3 rounded-lg mb-3 w-full">
                  <div className="text-center md:text-left">
                    <span className="font-semibold">Instalação:</span> R$ {comboOption.installationPrice.toLocaleString('pt-BR')}
                  </div>
                  <div className="text-gray-200 line-through text-center md:text-left text-sm">
                    R$ {comboOption.originalInstallationPrice.toLocaleString('pt-BR')}
                  </div>
                  <div className="text-yellow-300 text-sm text-center md:text-left">
                    {comboOption.discountPercentage}% de desconto
                  </div>
                </div>
              </div>
            </div>
            
            <Link 
              to="/services/combo" 
              className="block w-full text-center py-3 px-4 rounded bg-yellow-500 hover:bg-yellow-400 text-gray-900 font-bold text-lg transition-colors duration-300"
            >
              Ver Detalhes do Pacote
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services; 