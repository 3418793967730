import React from 'react';

interface PartnershipInfoProps {
  partnerName: string;
  partnerDescription: string;
  logoSrc?: string;
}

const PartnershipInfo: React.FC<PartnershipInfoProps> = ({ 
  partnerName, 
  partnerDescription, 
  logoSrc 
}) => {
  return (
    <div className="bg-background-light rounded-lg shadow-md overflow-hidden">
      <div className="p-6">
        <div className="flex flex-col md:flex-row items-center md:items-start gap-6">
          {logoSrc && (
            <div className="w-32 h-32 flex-shrink-0">
              <img 
                src={logoSrc} 
                alt={`${partnerName} logo`} 
                className="w-full h-full object-contain"
              />
            </div>
          )}
          
          <div>
            <h3 className="text-xl font-semibold text-primary-dark mb-3">Parceria com {partnerName}</h3>
            <p className="text-primary">{partnerDescription}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnershipInfo; 