import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../assets/images/logo_nyctea.jpg';

const Header: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const isActive = (path: string) => {
    return location.pathname === path;
  };

  return (
    <header className="bg-background-light py-4 shadow-md border-b border-background">
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center">
          {/* Logo */}
          <Link to="/" className="flex items-center">
            <img src={logo} alt="Nyctea Logo" className="h-10 w-auto rounded-full" />
            <span className="ml-3 text-primary font-bold text-xl">Nyctea</span>
          </Link>

          {/* Desktop Navigation */}
          <nav className="hidden md:flex space-x-8">
            <NavLink to="/" isActive={isActive('/')} onClick={closeMenu}>
              Início
            </NavLink>
            <NavLink to="/about" isActive={isActive('/about')} onClick={closeMenu}>
              Sobre
            </NavLink>
            <NavLink to="/services" isActive={isActive('/services')} onClick={closeMenu}>
              Serviços
            </NavLink>
            <NavLink to="/blog" isActive={isActive('/blog')} onClick={closeMenu}>
              Blog
            </NavLink>
            <NavLink to="/ebooks" isActive={isActive('/ebooks')} onClick={closeMenu}>
              E-books
            </NavLink>
            <NavLink to="/contact" isActive={isActive('/contact')} onClick={closeMenu}>
              Contato
            </NavLink>
          </nav>

          {/* Mobile Menu Button */}
          <button 
            className="md:hidden text-primary focus:outline-none"
            onClick={toggleMenu}
            aria-label="Toggle menu"
          >
            {isMenuOpen ? (
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            ) : (
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            )}
          </button>
        </div>

        {/* Mobile Navigation */}
        {isMenuOpen && (
          <nav className="md:hidden mt-4 py-2 bg-background rounded-lg shadow-md">
            <div className="flex flex-col space-y-2 p-2">
              <NavLink to="/" isActive={isActive('/')} onClick={closeMenu} isMobile={true}>
                Início
              </NavLink>
              <NavLink to="/about" isActive={isActive('/about')} onClick={closeMenu} isMobile={true}>
                Sobre
              </NavLink>
              <NavLink to="/services" isActive={isActive('/services')} onClick={closeMenu} isMobile={true}>
                Serviços
              </NavLink>
              <NavLink to="/blog" isActive={isActive('/blog')} onClick={closeMenu} isMobile={true}>
                Blog
              </NavLink>
              <NavLink to="/ebooks" isActive={isActive('/ebooks')} onClick={closeMenu} isMobile={true}>
                E-books
              </NavLink>
              <NavLink to="/contact" isActive={isActive('/contact')} onClick={closeMenu} isMobile={true}>
                Contato
              </NavLink>
            </div>
          </nav>
        )}
      </div>
    </header>
  );
};

interface NavLinkProps {
  to: string;
  isActive: boolean;
  onClick: () => void;
  children: React.ReactNode;
  isMobile?: boolean;
}

const NavLink: React.FC<NavLinkProps> = ({ to, isActive, onClick, children, isMobile = false }) => {
  const baseClasses = "transition-colors duration-200";
  const mobileClasses = "block w-full px-4 py-2 text-left rounded";
  const desktopClasses = "";
  
  const activeClasses = "text-accent font-semibold";
  const inactiveClasses = "text-text hover:text-accent";
  
  const classes = `
    ${baseClasses}
    ${isMobile ? mobileClasses : desktopClasses}
    ${isActive ? activeClasses : inactiveClasses}
  `;
  
  return (
    <Link to={to} className={classes} onClick={onClick}>
      {children}
    </Link>
  );
};

export default Header; 