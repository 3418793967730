import { Ebook } from '../types/ebook';
import ebookAgentesLLM from '../assets/images/ebooks/ebook-agentes-llm.png';

export const ebooks: Ebook[] = [
  {
    id: '1',
    title: 'Introdução aos Agentes de LLM: Uma Abordagem Prática',
    description: 'Crie aplicações inteligentes com o poder da IA Generativa. Com este e-book você será capaz de construir funcionalidades surpreendentes e incorporar o que há de melhor da inteligência artificial em seus projetos. Transforme a maneira como os usuários interagem com seus produtos.',
    longDescription: 'Com o e-book "Introdução aos Agentes de LLM", domine a automação inteligente e crie aplicações poderosas e automatizadas. Aprenda a desenvolver agentes de LLM que realizam tarefas repetitivas de forma precisa e eficiente, mesmo sem experiência prévia em IA. Aplique imediatamente o que aprendeu em cenários reais, aumentando sua produtividade e habilidades. Adquira o e-book agora e transforme a interação com IA em soluções eficazes.',
    coverImage: ebookAgentesLLM,
    author: 'Equipe Nyctea',
    price: '',
    externalUrl: 'https://go.hotmart.com/F95450739L',
    releaseDate: '2024-11-02',
    categories: ['Inteligência Artificial', 'Automação', 'Desenvolvimento', 'LLM'],
    featured: true
  }
]; 