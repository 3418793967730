import React from 'react';
import { ComboOption } from '../types/service';
import { Link } from 'react-router-dom';
import { trackServiceInteraction } from '../utils/analytics';

interface ComboCardProps {
  combo: ComboOption;
}

const ComboCard: React.FC<ComboCardProps> = ({ combo }) => {
  // Calcular a economia mensal
  const monthlySavings = combo.originalPrice - combo.price;
  // Calcular a economia na instalação
  const installationSavings = combo.originalInstallationPrice - combo.installationPrice;

  // Rastreia quando o usuário clica em "Contratar Pacote Completo"
  const handleClick = () => {
    try {
      trackServiceInteraction(
        'combo',
        combo.title,
        'contract_combo'
      );
    } catch (error) {
      console.error('Erro ao rastrear interação com combo:', error);
    }
  };

  // Constrói a URL para o formulário de contato com parâmetros de consulta
  const buildContactUrl = () => {
    const params = new URLSearchParams();
    
    // Adiciona informações do combo aos parâmetros
    params.append('service', 'Pacote Completo');
    params.append('plan', combo.title);
    params.append('action', 'contract');
    
    // Adiciona os serviços incluídos como uma lista
    combo.includedServices.forEach((service, index) => {
      params.append(`included_service_${index + 1}`, service);
    });
    
    // Adiciona o número total de serviços incluídos
    params.append('included_services_count', combo.includedServices.length.toString());
    
    return `/contact?${params.toString()}`;
  };

  return (
    <div className="bg-gradient-to-br from-primary-dark to-primary rounded-lg shadow-xl overflow-hidden">
      <div className="bg-accent text-white py-2 px-4 text-center">
        <span className="text-lg font-bold">Economize R$ {monthlySavings.toLocaleString('pt-BR')} mensais + R$ {installationSavings.toLocaleString('pt-BR')} na instalação</span>
      </div>
      <div className="p-8 text-white">
        <div className="flex flex-col lg:flex-row gap-8">
          <div className="lg:w-2/3">
            <h3 className="text-2xl font-bold mb-2">{combo.title}</h3>
            <p className="text-gray-100 mb-6">{combo.description}</p>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
              <div>
                <h4 className="text-xl font-semibold mb-3 text-white">Serviços Incluídos:</h4>
                <ul className="space-y-2">
                  {combo.includedServices.map((service, index) => (
                    <li key={index} className="flex items-start">
                      <svg className="h-5 w-5 text-yellow-300 mt-0.5 mr-2 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                      </svg>
                      <span className="text-gray-100">{service}</span>
                    </li>
                  ))}
                </ul>
              </div>
              
              <div>
                <h4 className="text-xl font-semibold mb-3 text-white">Benefícios Exclusivos:</h4>
                <ul className="space-y-2">
                  {combo.features.map((feature, index) => (
                    <li key={index} className="flex items-start">
                      <svg className="h-5 w-5 text-yellow-300 mt-0.5 mr-2 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                      </svg>
                      <span className="text-gray-100">{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          
          <div className="lg:w-1/3 bg-primary-dark/30 p-6 rounded-lg">
            <div className="mb-6">
              <h4 className="text-xl font-semibold mb-4 text-white text-center">Investimento</h4>
              
              <div className="bg-primary-dark/40 p-4 rounded-lg mb-4">
                <div className="text-center mb-1">
                  <span className="text-sm text-gray-300">Mensalidade</span>
                </div>
                <div className="flex items-center justify-center mb-1">
                  <span className="text-3xl font-bold">R$ {combo.price.toLocaleString('pt-BR')}</span>
                  <span className="ml-2 text-gray-100">/mês</span>
                </div>
                <div className="text-gray-200 line-through text-center text-sm">
                  R$ {combo.originalPrice.toLocaleString('pt-BR')}/mês
                </div>
                <div className="text-yellow-300 text-sm text-center">
                  Economia de R$ {monthlySavings.toLocaleString('pt-BR')} mensais
                </div>
              </div>
              
              <div className="bg-primary-dark/40 p-4 rounded-lg mb-6">
                <div className="text-center mb-1">
                  <span className="text-sm text-gray-300">Instalação única</span>
                </div>
                <div className="flex items-center justify-center mb-1">
                  <span className="text-3xl font-bold">R$ {combo.installationPrice.toLocaleString('pt-BR')}</span>
                </div>
                <div className="text-gray-200 line-through text-center text-sm">
                  R$ {combo.originalInstallationPrice.toLocaleString('pt-BR')}
                </div>
                <div className="text-yellow-300 text-sm text-center">
                  {combo.discountPercentage}% de desconto
                </div>
              </div>
            </div>
            
            <Link 
              to={buildContactUrl()} 
              className="block w-full text-center py-3 px-4 rounded bg-yellow-500 hover:bg-yellow-400 text-gray-900 font-bold text-lg transition-colors duration-300"
              onClick={handleClick}
            >
              Contratar Pacote Completo
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComboCard; 