import React from 'react';
import { Link } from 'react-router-dom';
import SEO from '../components/common/SEO';
import owlImage from '../assets/images/about_owl.png';
import founderImage from '../assets/images/about_founder.jpg';
import innovationImage from '../assets/images/about_values_innovation.png';
import facilityImage from '../assets/images/about_values_facility.png';
import impactImage from '../assets/images/about_values_impact.png';
import n8nLogo from '../assets/images/logo_n8n.png';
import gptLogo from '../assets/images/logo_gpt.png';
import claudeLogo from '../assets/images/logo_claude.png';
import metaLogo from '../assets/images/logo_meta.png';
import telegramLogo from '../assets/images/logo_telegram.svg';
import googleAdsLogo from '../assets/images/logo_google_ads.jpg';
import getEnergyLogo from '../assets/images/logo_get_energy.png';
import pythonLogo from '../assets/images/logo_python.png';
import whatsappLogo from '../assets/images/logo_whatsapp.png';
import instagramLogo from '../assets/images/logo_instagram.jpeg';

const About: React.FC = () => {
  // Structured data for the about page
  const aboutSchema = {
    "@context": "https://schema.org",
    "@type": "AboutPage",
    "name": "Sobre a Nyctea",
    "description": "Conheça nossa história, missão e visão para o futuro da IA aplicada aos negócios",
    "mainEntity": {
      "@type": "Organization",
      "name": "Nyctea",
      "description": "Empresa especializada em aplicação de inteligência artificial para negócios",
      "foundingDate": "2022",
      "founder": {
        "@type": "Person",
        "name": "Gabriel Nyctea"
      }
    }
  };

  return (
    <div>
      <SEO 
        title="Sobre Nós"
        description="Conheça a Nyctea, nossa história, missão e como utilizamos inteligência artificial para transformar negócios e criar soluções inovadoras."
        canonical="/about"
        structuredData={aboutSchema}
      />
      
      {/* Cabeçalho da Página */}
      <section className="bg-primary-dark py-16">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto text-center">
            <h1 className="text-4xl font-bold text-background-light mb-4">Sobre a Nyctea</h1>
            <p className="text-xl text-background">
              Conheça nossa história, missão e visão para o futuro da IA
            </p>
          </div>
        </div>
      </section>

      {/* História e Significado */}
      <section className="py-16 bg-background">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-start">
            {/* Coluna da Imagem */}
            <div className="flex justify-center items-start lg:sticky lg:top-24">
              <img 
                src={owlImage} 
                alt="Coruja das Neves - Símbolo da Nyctea" 
                className="rounded-lg shadow-xl w-full"
              />
            </div>

            {/* Coluna do Conteúdo */}
            <div className="space-y-10">
              {/* História */}
              <div>
                <h2 className="text-3xl font-bold text-primary-dark mb-6">Nossa História</h2>
                <p className="text-text mb-4">
                  A Nyctea foi fundada em 2023, em meio à revolução dos Modelos de Linguagem de Grande Escala (LLMs), com o propósito de transformar a maneira como empresas utilizam Inteligência Artificial.
                </p>
                <p className="text-text mb-4">
                  Nascemos com uma visão clara: desenvolver soluções inovadoras que aceleram negócios, automatizam processos e desbloqueiam novas oportunidades através do poder da IA.
                </p>
                <p className="text-text">
                  Hoje, trabalhamos com empresas de diversos setores, desde startups até negócios estabelecidos, ajudando-os a incorporar IA de forma prática e estratégica para alcançar seus objetivos.
                </p>
              </div>

              {/* Significado do Nome */}
              <div>
                <h2 className="text-3xl font-bold text-primary-dark mb-6">O Significado de Nyctea</h2>
                <p className="text-text mb-4">
                  O nome Nyctea remete à <em>Bubo scandiacus</em>, a nomenclatura científica da coruja das neves. Essa escolha não é apenas estética, mas representa a essência da empresa: visão estratégica, inteligência e precisão.
                </p>
                <p className="text-text mb-4">
                  A coruja das neves é um símbolo de adaptabilidade e clareza, enxergando além do óbvio para encontrar oportunidades mesmo em cenários desafiadores.
                </p>
                <h3 className="text-xl font-semibold text-primary mb-3">A Coruja como Símbolo</h3>
                <p className="text-text">
                  Assim como a coruja das neves se destaca por sua capacidade de ver no escuro e caçar silenciosamente com eficiência, a Nyctea busca oferecer soluções estratégicas que maximizam o impacto da IA nos negócios. Sua resiliência ao frio extremo do Ártico reflete a abordagem da empresa: enfrentar desafios com sofisticação, integridade e inovação contínua.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Fundador */}
      <section className="py-16 bg-background-light">
        <div className="container mx-auto px-4">
          <div className="max-w-4xl mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 items-center">
              <div className="md:col-span-2 order-2 md:order-1">
                <h2 className="text-3xl font-bold text-primary-dark mb-4">Liderança</h2>
                <h3 className="text-xl font-semibold text-primary mb-2">Aron Ifanger</h3>
                <p className="text-accent-light mb-2">Fundador & CEO</p>
                <p className="text-text mb-4">
                  Especialista em inteligência artificial com mais de dez anos de experiência em machine learning, NLP e IA generativa, Aron iniciou sua carreira na área de ciência de dados. Atuou em grandes empresas como Ambev e Semantix, onde liderou projetos avançados de modelagem e análise de dados. Atualmente, também ocupa o cargo de CTO na Precision Data, conduzindo iniciativas que aplicam inteligência artificial para aprimorar a experiência e o uso estratégico de dados no setor da saúde.
                </p>
                <p className="text-text">
                  Ao identificar o potencial transformador dos modelos de linguagem de grande escala (LLMs), Aron fundou a Nyctea com o objetivo de tornar a inteligência artificial acessível e prática para pequenas e médias empresas, permitindo que negócios de todos os portes aproveitem tecnologias avançadas de forma estratégica.
                </p>
              </div>
              <div className="order-1 md:order-2">
                <img 
                  src={founderImage} 
                  alt="Aron Ifanger" 
                  className="rounded-full shadow-lg w-full max-w-xs mx-auto"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Missão e Valores */}
      <section className="py-16">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto text-center mb-12">
            <h2 className="text-3xl font-bold text-primary-dark mb-6">Transformando Negócios com Inteligência</h2>
            <p className="text-xl text-text mb-8">
              Somos guiados por princípios fundamentais que orientam cada projeto e decisão
            </p>
            
            {/* Missão e Visão em cards separados */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-16">
              {/* Missão */}
              <div className="bg-background-light p-6 rounded-lg shadow-md h-full">
                <h3 className="text-2xl font-semibold text-primary-dark mb-4">Nossa Missão</h3>
                <p className="text-text">
                  Tornar a inteligência artificial acessível e poderosa para empresas que desejam crescer e se destacar em um mercado em constante evolução.
                </p>
              </div>
              
              {/* Visão */}
              <div className="bg-background-light p-6 rounded-lg shadow-md h-full">
                <h3 className="text-2xl font-semibold text-primary-dark mb-4">Nossa Visão</h3>
                <p className="text-text">
                  Acreditamos que a IA não é apenas uma ferramenta, mas um diferencial competitivo essencial para negócios modernos, combinando expertise técnica e visão estratégica para criar soluções que realmente fazem a diferença.
                </p>
              </div>
            </div>
          </div>

          {/* Valores com apenas uma frase introdutória */}
          <div className="max-w-3xl mx-auto text-center mb-8">
            <p className="text-xl text-text italic mb-8">
              Três pilares que sustentam nossa jornada de inovação
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {/* Valor 1 */}
            <div className="bg-background-light border-t-4 border-accent rounded-lg p-6 text-center shadow-md">
              <img src={innovationImage} alt="Inovação" className="w-full h-48 object-cover rounded-lg mb-4" />
              <h3 className="text-xl font-semibold text-primary-dark mb-3">Inovação</h3>
              <p className="text-text">
                Exploramos constantemente novas tecnologias e abordagens para entregar soluções de ponta em IA.
              </p>
            </div>

            {/* Valor 2 */}
            <div className="bg-background-light border-t-4 border-accent rounded-lg p-6 text-center shadow-md">
              <img src={facilityImage} alt="Facilidade" className="w-full h-48 object-cover rounded-lg mb-4" />
              <h3 className="text-xl font-semibold text-primary-dark mb-3">Facilidade</h3>
              <p className="text-text">
                Tornamos simples e intuitivo o uso de tecnologias avançadas, permitindo que até pequenos negócios aproveitem o poder da IA.
              </p>
            </div>

            {/* Valor 3 */}
            <div className="bg-background-light border-t-4 border-accent rounded-lg p-6 text-center shadow-md">
              <img src={impactImage} alt="Impacto" className="w-full h-48 object-cover rounded-lg mb-4" />
              <h3 className="text-xl font-semibold text-primary-dark mb-3">Impacto</h3>
              <p className="text-text">
                Focamos em soluções que geram resultados tangíveis e transformam positivamente os negócios.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Tecnologias e Parcerias */}
      <section className="py-16 bg-background-light">
        <div className="container mx-auto px-4">
          <div className="max-w-4xl mx-auto">
            <div className="text-center mb-12">
              <h2 className="text-3xl font-bold text-primary-dark mb-6">Nosso Ecossistema</h2>
              <p className="text-xl text-text mb-8">
                Utilizamos tecnologias de ponta e parcerias estratégicas para entregar soluções completas
              </p>
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-12 mb-8">
              {/* Technologies */}
              <div className="flex flex-col h-full">
                <h3 className="text-2xl font-semibold text-primary-dark mb-6 text-center">Tecnologias</h3>
                <div className="bg-background p-6 rounded-lg shadow-md flex-grow flex flex-col justify-center">
                  <div className="grid grid-cols-2 sm:grid-cols-3 gap-6 my-auto">
                    <div className="flex flex-col items-center">
                      <img src={n8nLogo} alt="N8N" className="w-16 h-16 mb-2 rounded-full object-cover" />
                      <span className="text-text text-sm">N8N</span>
                    </div>
                    <div className="flex flex-col items-center">
                      <img src={gptLogo} alt="GPT" className="w-16 h-16 mb-2 rounded-full object-cover" />
                      <span className="text-text text-sm">ChatGPT</span>
                    </div>
                    <div className="flex flex-col items-center">
                      <img src={claudeLogo} alt="AI" className="w-16 h-16 mb-2 rounded-full object-cover" />
                      <span className="text-text text-sm">Antropic</span>
                    </div>
                    <div className="flex flex-col items-center">
                      <img src={metaLogo} alt="Meta" className="w-16 h-16 mb-2 rounded-full object-cover" />
                      <span className="text-text text-sm">Meta Ads</span>
                    </div>
                    <div className="flex flex-col items-center">
                      <img src={telegramLogo} alt="TG" className="w-16 h-16 mb-2 rounded-full object-cover" />
                      <span className="text-text text-sm">Telegram</span>
                    </div>
                    <div className="flex flex-col items-center">
                      <img src={googleAdsLogo} alt="Ads" className="w-16 h-16 mb-2 rounded-full object-cover" />
                      <span className="text-text text-sm">Google Ads</span>
                    </div>
                    <div className="flex flex-col items-center">
                      <img src={pythonLogo} alt="Ads" className="w-16 h-16 mb-2 rounded-full object-cover" />
                      <span className="text-text text-sm">Python</span>
                    </div>
                    <div className="flex flex-col items-center">
                      <img src={whatsappLogo} alt="Ads" className="w-16 h-16 mb-2 rounded-full object-cover" />
                      <span className="text-text text-sm">WhatsApp</span>
                    </div>
                    <div className="flex flex-col items-center">
                      <img src={instagramLogo} alt="Ads" className="w-16 h-16 mb-2 rounded-full object-cover" />
                      <span className="text-text text-sm">Instagram</span>
                    </div>
                  </div>
                </div>
              </div>
              
              {/* Partnerships */}
              <div className="flex flex-col h-full">
                <h3 className="text-2xl font-semibold text-primary-dark mb-6 text-center">Parcerias</h3>
                <div className="bg-background p-6 rounded-lg shadow-md flex-grow flex flex-col justify-center">
                  <div className="flex flex-col items-center justify-center my-auto">
                    <img src={getEnergyLogo} alt="GetEnergy" className="w-32 h-32 mb-4" />
                    <p className="text-text text-center mt-2">
                      Parceria estratégica com a GetEnergy, uma empresa de mídia indoor, expandindo nosso alcance em soluções de marketing digital.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Seção CTA */}
      <section className="py-16 bg-primary-dark">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold text-background-light mb-6">
            Pronto para transformar seu negócio com IA?
          </h2>
          <p className="text-xl text-background mb-8 max-w-2xl mx-auto">
            Vamos discutir como podemos ajudar sua empresa a crescer com soluções de inteligência artificial sob medida.
          </p>
          <Link to="/contact" className="btn-accent text-lg px-8 py-3">
            Entre em Contato
          </Link>
        </div>
      </section>
    </div>
  );
};

export default About; 