import { createClient } from '@supabase/supabase-js';
import { BlogPost } from '../types/blog';

// Essas variáveis de ambiente devem ser configuradas no ambiente de produção
// Para desenvolvimento local, você pode definir valores diretamente aqui
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL || 'https://your-supabase-url.supabase.co';
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY || 'your-supabase-anon-key';

// Criar o cliente Supabase
export const supabase = createClient(supabaseUrl, supabaseAnonKey);

// Função para enviar dados de contato para o Supabase
export const submitContactForm = async (formData: {
  name: string;
  email: string;
  phone: string;
  subject: string;
  message: string;
}) => {
  try {
    const { data, error } = await supabase
      .from('contacts')
      .insert([
        {
          name: formData.name,
          email: formData.email,
          phone: formData.phone,
          subject: formData.subject,
          message: formData.message,
          created_at: new Date().toISOString(),
        },
      ])
      .select();

    if (error) {
      throw error;
    }

    return { success: true, data };
  } catch (error) {
    console.error('Error submitting contact form:', error);
    return { success: false, error };
  }
};

// Funções para gerenciar posts do blog

/**
 * Busca todos os posts do blog do Supabase
 */
export const fetchBlogPosts = async (): Promise<{ data: BlogPost[] | null; error: any }> => {
  try {
    const { data, error } = await supabase
      .from('blog_posts')
      .select('*')
      .order('date', { ascending: false });

    if (error) {
      throw error;
    }

    return { data, error: null };
  } catch (error) {
    console.error('Error fetching blog posts:', error);
    return { data: null, error };
  }
};

/**
 * Busca um post específico pelo slug
 */
export const fetchBlogPostBySlug = async (slug: string): Promise<{ data: BlogPost | null; error: any }> => {
  try {
    const { data, error } = await supabase
      .from('blog_posts')
      .select('*')
      .eq('slug', slug)
      .single();

    if (error) {
      throw error;
    }

    return { data, error: null };
  } catch (error) {
    console.error(`Error fetching blog post with slug ${slug}:`, error);
    return { data: null, error };
  }
};

/**
 * Busca posts por tag
 */
export const fetchBlogPostsByTag = async (tag: string): Promise<{ data: BlogPost[] | null; error: any }> => {
  try {
    const { data, error } = await supabase
      .from('blog_posts')
      .select('*')
      .contains('tags', [tag])
      .order('date', { ascending: false });

    if (error) {
      throw error;
    }

    return { data, error: null };
  } catch (error) {
    console.error(`Error fetching blog posts with tag ${tag}:`, error);
    return { data: null, error };
  }
};

/**
 * Busca posts por termo de pesquisa (no título ou conteúdo)
 */
export const searchBlogPosts = async (searchTerm: string): Promise<{ data: BlogPost[] | null; error: any }> => {
  try {
    const { data, error } = await supabase
      .from('blog_posts')
      .select('*')
      .or(`title.ilike.%${searchTerm}%,excerpt.ilike.%${searchTerm}%,content.ilike.%${searchTerm}%`)
      .order('date', { ascending: false });

    if (error) {
      throw error;
    }

    return { data, error: null };
  } catch (error) {
    console.error(`Error searching blog posts with term ${searchTerm}:`, error);
    return { data: null, error };
  }
}; 