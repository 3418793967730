import React from 'react';
import { Link } from 'react-router-dom';
import { getServiceOptionsBySlug } from '../../data/servicesData';
import { trackServiceInteraction } from '../../utils/analytics';
import { ServiceOption } from '../../types/service';

const SocialMediaPricingTable: React.FC = () => {
  const options = getServiceOptionsBySlug('social-media');

  if (!options || options.length === 0) {
    return (
      <div className="text-center p-8 bg-background-light rounded-lg shadow-md">
        <p className="text-primary-dark">Nenhum plano disponível no momento. Entre em contato para uma proposta personalizada.</p>
        <Link to="/contact" className="btn-accent mt-4 inline-block">
          Solicitar Proposta
        </Link>
      </div>
    );
  }

  // Função para rastrear cliques nos botões de ação
  const handleClick = (optionTitle: string, action: 'hire' | 'quote') => {
    trackServiceInteraction(
      'social-media',
      'Gestão de Redes Sociais',
      action === 'hire' ? `hire_option_${optionTitle}` : `request_quote_${optionTitle}`
    );
  };

  // Função para construir a URL de contato com parâmetros
  const buildContactUrl = (option: ServiceOption, action: 'hire' | 'quote') => {
    const params = new URLSearchParams();
    params.append('service', 'Gestão de Redes Sociais');
    params.append('option', option.title);
    params.append('action', action === 'hire' ? 'contratar' : 'proposta');
    return `/contact?${params.toString()}`;
  };

  // Função auxiliar para obter o valor de uma feature específica
  const getFeatureValue = (option: ServiceOption, featureName: string): string => {
    // Se for o Pacote Personalizado e não encontrar a feature, retornar mensagens personalizadas
    if (option.title === 'Pacote Personalizado') {
      const feature = option.features.find(f => f.includes(featureName));
      if (feature) return feature;
      
      // Mensagens personalizadas para cada tipo de feature
      if (featureName === 'vídeo') return 'Produção personalizada conforme necessidade';
      if (featureName === 'comentário') return 'Gestão completa de interações';
      if (featureName === 'tráfego') return 'Estratégia personalizada de anúncios';
      if (featureName === 'relatório') return 'Relatórios detalhados com métricas personalizadas';
      if (featureName === 'suporte') return 'Suporte prioritário com gerente dedicado';
      
      return 'Personalizado conforme necessidade';
    }
    
    // Para os outros pacotes, manter o comportamento original
    const feature = option.features.find(f => f.includes(featureName));
    return feature || 'Não incluído';
  };

  // Função específica para obter o valor correto de redes sociais
  const getSocialNetworksValue = (option: ServiceOption): string => {
    if (option.title === 'Pacote Essencial') {
      return 'Instagram ou Facebook';
    } else if (option.title === 'Pacote Profissional') {
      return 'Instagram, Facebook ou TikTok';
    } else if (option.title === 'Pacote Personalizado') {
      return 'Instagram, Facebook, TikTok ou Youtube';
    }
    return 'Não incluído';
  };

  // Função específica para obter o valor correto de posts mensais
  const getPostsValue = (option: ServiceOption): string => {
    if (option.title === 'Pacote Essencial') {
      return '8 posts mensais';
    } else if (option.title === 'Pacote Profissional') {
      return '12 posts mensais';
    } else if (option.title === 'Pacote Personalizado') {
      return 'Quantidade personalizada de posts';
    }
    return 'Não incluído';
  };

  // Função específica para obter o valor correto de stories
  const getStoriesValue = (option: ServiceOption): string => {
    if (option.title === 'Pacote Essencial') {
      return 'Não incluído';
    } else if (option.title === 'Pacote Profissional') {
      return '4 stories semanais';
    } else if (option.title === 'Pacote Personalizado') {
      return 'Quantidade personalizada de stories';
    }
    return 'Não incluído';
  };

  return (
    <div className="overflow-x-auto">
      <table className="w-full border-collapse">
        <thead>
          <tr className="bg-primary-dark text-background-light">
            <th className="p-4 text-left border-b border-background/10">Recursos</th>
            {options.map((option) => (
              <th key={option.id} className="p-4 text-center border-b border-background/10">
                <div className="font-bold text-xl mb-2">{option.title}</div>
                <div className="text-sm opacity-80">Ideal para {option.description}</div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {/* Preço Mensal */}
          <tr className="bg-background-light hover:bg-background-light/80">
            <td className="p-4 border-b border-primary/10 font-medium">Preço Mensal</td>
            {options.map((option) => (
              <td key={option.id} className="p-4 text-center border-b border-primary/10">
                <div className="font-bold text-xl text-primary-dark">
                  {option.price === null 
                    ? 'Sob Consulta' 
                    : `R$ ${option.price}`}
                </div>
                {option.maintenanceFee && (
                  <div className="text-sm text-primary-dark/70">Manutenção: R$ {option.maintenanceFee}/mês</div>
                )}
              </td>
            ))}
          </tr>

          {/* Redes Sociais Incluídas */}
          <tr className="bg-background hover:bg-background/90">
            <td className="p-4 border-b border-primary/10 font-medium">Redes Sociais</td>
            {options.map((option) => (
              <td key={option.id} className="p-4 text-center border-b border-primary/10">
                {getSocialNetworksValue(option)}
              </td>
            ))}
          </tr>

          {/* Posts Mensais */}
          <tr className="bg-background-light hover:bg-background-light/80">
            <td className="p-4 border-b border-primary/10 font-medium">Posts Mensais</td>
            {options.map((option) => (
              <td key={option.id} className="p-4 text-center border-b border-primary/10">
                {getPostsValue(option)}
              </td>
            ))}
          </tr>

          {/* Stories */}
          <tr className="bg-background hover:bg-background/90">
            <td className="p-4 border-b border-primary/10 font-medium">Stories</td>
            {options.map((option) => (
              <td key={option.id} className="p-4 text-center border-b border-primary/10">
                {getStoriesValue(option)}
              </td>
            ))}
          </tr>

          {/* Vídeos */}
          <tr className="bg-background-light hover:bg-background-light/80">
            <td className="p-4 border-b border-primary/10 font-medium">Vídeos</td>
            {options.map((option) => (
              <td key={option.id} className="p-4 text-center border-b border-primary/10">
                {getFeatureValue(option, 'vídeo')}
              </td>
            ))}
          </tr>

          {/* Resposta a Comentários */}
          <tr className="bg-background hover:bg-background/90">
            <td className="p-4 border-b border-primary/10 font-medium">Resposta a Comentários</td>
            {options.map((option) => (
              <td key={option.id} className="p-4 text-center border-b border-primary/10">
                {getFeatureValue(option, 'comentário')}
              </td>
            ))}
          </tr>

          {/* Tráfego Pago */}
          <tr className="bg-background-light hover:bg-background-light/80">
            <td className="p-4 border-b border-primary/10 font-medium">Tráfego Pago</td>
            {options.map((option) => (
              <td key={option.id} className="p-4 text-center border-b border-primary/10">
                {getFeatureValue(option, 'tráfego')}
              </td>
            ))}
          </tr>

          {/* Relatórios */}
          <tr className="bg-background hover:bg-background/90">
            <td className="p-4 border-b border-primary/10 font-medium">Relatórios</td>
            {options.map((option) => (
              <td key={option.id} className="p-4 text-center border-b border-primary/10">
                {getFeatureValue(option, 'relatório')}
              </td>
            ))}
          </tr>

          {/* Suporte */}
          <tr className="bg-background-light hover:bg-background-light/80">
            <td className="p-4 border-b border-primary/10 font-medium">Suporte</td>
            {options.map((option) => (
              <td key={option.id} className="p-4 text-center border-b border-primary/10">
                {getFeatureValue(option, 'suporte')}
              </td>
            ))}
          </tr>

          {/* Botões de Ação */}
          <tr className="bg-primary-dark/5">
            <td className="p-4"></td>
            {options.map((option) => (
              <td key={option.id} className="p-4 text-center">
                <div className="flex flex-col space-y-3">
                  {option.price !== null ? (
                    <Link 
                      to={buildContactUrl(option, 'hire')}
                      className="btn-accent w-full py-2"
                      onClick={() => handleClick(option.title, 'hire')}
                    >
                      Contratar Agora
                    </Link>
                  ) : (
                    <Link 
                      to={buildContactUrl(option, 'quote')}
                      className="btn-accent w-full py-2"
                      onClick={() => handleClick(option.title, 'quote')}
                    >
                      Solicitar Proposta
                    </Link>
                  )}
                </div>
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default SocialMediaPricingTable; 