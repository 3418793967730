import React from 'react';

interface UseCaseInfoProps {
  title: string;
  problem: string;
  solution: string[];
  result: string;
  icon?: string;
}

const UseCaseInfo: React.FC<UseCaseInfoProps> = ({ 
  title, 
  problem, 
  solution, 
  result,
  icon = "chat" // Valores possíveis: chat, calendar, faq
}) => {
  const getIcon = () => {
    switch(icon) {
      case 'calendar':
        return (
          <svg className="w-12 h-12 text-accent" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"></path>
          </svg>
        );
      case 'faq':
        return (
          <svg className="w-12 h-12 text-accent" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
          </svg>
        );
      default: // chat
        return (
          <svg className="w-12 h-12 text-accent" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"></path>
          </svg>
        );
    }
  };

  return (
    <div className="bg-background-light rounded-lg shadow-md p-6 transition-transform duration-300 hover:transform hover:scale-105">
      <div className="flex items-start mb-4">
        <div className="mr-4 flex-shrink-0">
          {getIcon()}
        </div>
        <h3 className="text-xl font-bold text-primary-dark">{title}</h3>
      </div>
      
      <div className="mb-4">
        <h4 className="text-lg font-semibold text-primary-dark mb-2">O Problema:</h4>
        <p className="text-primary">{problem}</p>
      </div>
      
      <div className="mb-4">
        <h4 className="text-lg font-semibold text-primary-dark mb-2">Nossa Solução:</h4>
        <ul className="space-y-2">
          {solution.map((item, index) => (
            <li key={index} className="flex items-start">
              <svg className="h-5 w-5 text-accent mt-0.5 mr-2 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
              </svg>
              <span className="text-primary">{item}</span>
            </li>
          ))}
        </ul>
      </div>
      
      <div>
        <h4 className="text-lg font-semibold text-primary-dark mb-2">Resultado:</h4>
        <p className="text-primary font-medium">{result}</p>
      </div>
    </div>
  );
};

export default UseCaseInfo; 