import { useState, useEffect, useCallback } from 'react';
import { BlogPost } from '../types/blog';
import { 
  fetchBlogPosts, 
  fetchBlogPostBySlug, 
  fetchBlogPostsByTag, 
  searchBlogPosts 
} from '../utils/supabaseClient';

interface UseBlogPostsReturn {
  posts: BlogPost[];
  post: BlogPost | null;
  loading: boolean;
  error: any;
  getAllPosts: () => Promise<void>;
  getPostBySlug: (slug: string) => Promise<BlogPost | null>;
  getPostsByTag: (tag: string) => Promise<void>;
  searchPosts: (term: string) => Promise<void>;
  filterPostsByTag: (tag: string | null) => void;
  filterPostsBySearch: (term: string) => void;
  filteredPosts: BlogPost[];
  allTags: string[];
}

export const useBlogPosts = (): UseBlogPostsReturn => {
  const [posts, setPosts] = useState<BlogPost[]>([]);
  const [post, setPost] = useState<BlogPost | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>(null);
  const [filteredPosts, setFilteredPosts] = useState<BlogPost[]>([]);
  const [allTags, setAllTags] = useState<string[]>([]);

  // Buscar todos os posts
  const getAllPosts = useCallback(async () => {
    setLoading(true);
    try {
      const { data, error } = await fetchBlogPosts();
      if (error) {
        throw error;
      }
      
      if (data) {
        setPosts(data);
        setFilteredPosts(data);
        
        // Extrair todas as tags únicas
        const tags = Array.from(
          new Set(
            data.flatMap((post: BlogPost) => post.tags || [])
          )
        );
        setAllTags(tags);
      }
    } catch (err) {
      setError(err);
      console.error('Error fetching blog posts:', err);
    } finally {
      setLoading(false);
    }
  }, []);

  // Buscar post por slug
  const getPostBySlug = useCallback(async (slug: string): Promise<BlogPost | null> => {
    setLoading(true);
    try {
      const { data, error } = await fetchBlogPostBySlug(slug);
      if (error) {
        throw error;
      }
      
      if (data) {
        setPost(data);
        return data;
      }
      return null;
    } catch (err) {
      setError(err);
      console.error(`Error fetching blog post with slug ${slug}:`, err);
      return null;
    } finally {
      setLoading(false);
    }
  }, []);

  // Buscar posts por tag
  const getPostsByTag = useCallback(async (tag: string) => {
    setLoading(true);
    try {
      const { data, error } = await fetchBlogPostsByTag(tag);
      if (error) {
        throw error;
      }
      
      if (data) {
        setPosts(data);
        setFilteredPosts(data);
      }
    } catch (err) {
      setError(err);
      console.error(`Error fetching blog posts with tag ${tag}:`, err);
    } finally {
      setLoading(false);
    }
  }, []);

  // Buscar posts por termo de pesquisa
  const searchPosts = useCallback(async (term: string) => {
    setLoading(true);
    try {
      const { data, error } = await searchBlogPosts(term);
      if (error) {
        throw error;
      }
      
      if (data) {
        setPosts(data);
        setFilteredPosts(data);
      }
    } catch (err) {
      setError(err);
      console.error(`Error searching blog posts with term ${term}:`, err);
    } finally {
      setLoading(false);
    }
  }, []);

  // Filtrar posts por tag (filtro local, sem nova requisição)
  const filterPostsByTag = useCallback((tag: string | null) => {
    if (!tag) {
      setFilteredPosts(posts);
      return;
    }
    
    const filtered = posts.filter(post => post.tags?.includes(tag));
    setFilteredPosts(filtered);
  }, [posts]);

  // Filtrar posts por termo de pesquisa (filtro local, sem nova requisição)
  const filterPostsBySearch = useCallback((term: string) => {
    if (!term) {
      setFilteredPosts(posts);
      return;
    }
    
    const searchTermLower = term.toLowerCase();
    const filtered = posts.filter(post => 
      post.title.toLowerCase().includes(searchTermLower) || 
      post.excerpt.toLowerCase().includes(searchTermLower) ||
      post.content.toLowerCase().includes(searchTermLower)
    );
    setFilteredPosts(filtered);
  }, [posts]);

  // Carregar posts ao montar o componente
  useEffect(() => {
    getAllPosts();
  }, [getAllPosts]);

  return {
    posts,
    post,
    loading,
    error,
    getAllPosts,
    getPostBySlug,
    getPostsByTag,
    searchPosts,
    filterPostsByTag,
    filterPostsBySearch,
    filteredPosts,
    allTags
  };
};

export default useBlogPosts; 